import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex col grow g0",
  style: {"overflow-y":"scroll"}
}
const _hoisted_2 = { class: "flex col grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeBar = _resolveComponent("HomeBar")!
  const _component_HomePage = _resolveComponent("HomePage")!
  const _component_SearchPage = _resolveComponent("SearchPage")!
  const _component_TranscriptionPage = _resolveComponent("TranscriptionPage")!
  const _component_ChordsPage = _resolveComponent("ChordsPage")!
  const _component_ProfilePage = _resolveComponent("ProfilePage")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex grow g0",
    style: _normalizeStyle(_ctx.container)
  }, [
    _createVNode(_component_HomeBar),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.state.page == _ctx.HomePageEnum.Home)
              ? (_openBlock(), _createBlock(_component_HomePage, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.state.page == _ctx.HomePageEnum.Search)
              ? (_openBlock(), _createBlock(_component_SearchPage, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.state.page == _ctx.HomePageEnum.Transcribe)
              ? (_openBlock(), _createBlock(_component_TranscriptionPage, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.state.page == _ctx.HomePageEnum.Chords)
              ? (_openBlock(), _createBlock(_component_ChordsPage, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.state.page == _ctx.HomePageEnum.Profile)
              ? (_openBlock(), _createBlock(_component_ProfilePage, { key: 4 }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }))
  ], 4))
}
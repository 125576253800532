import IvorySong from "./songs/IvorySong";

export default class SearchResults {

    public songs: IvorySong[] = [];

    public getLength(): number {
        return this.songs.length;
    }

    constructor(songs: IvorySong[] = []) {
        this.songs = songs;
    }

}
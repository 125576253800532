export default class IvoryTempoChange {

  public tempo: number = 0;

  public noteIndex: number = 0;


  constructor(tempo: number, noteIndex: number) {
    this.tempo = tempo;
    this.noteIndex = noteIndex;
  }
  static fromJson(x: any) {
    return new IvoryTempoChange(x.tempo, x.noteIndex);
  }
}

import { h, render } from "vue";
import SeModal from "@/components/global/IvoryModal.vue";
import IvoryDownloadModal from "@/components/global/IvoryDownloadModal.vue";
import IvorySong from "@/model/songs/IvorySong";

export function openDownload(song: IvorySong) {
  const modalContainer = document.createElement("div");
  document.body.appendChild(modalContainer);

  // Create the component with a default slot containing the span
  const snackbarComponent = h(IvoryDownloadModal, { song }, {});

  render(snackbarComponent, modalContainer);

  const cmp = <any>snackbarComponent.component!;
  cmp.proxy!.open();

  return cmp.proxy!;
}

export function open(text: string, title: string = "Infos") {
  const modalContainer = document.createElement("div");
  document.body.appendChild(modalContainer);

  // Create the component with a default slot containing the span
  const snackbarComponent = h(
    SeModal,
    { title },
    {
      default: () =>
        h("span", {
          class: "text-muted",
          innerHTML: text,
        }),
    }
  );

  render(snackbarComponent, modalContainer);

  const cmp = <any>snackbarComponent.component!;
  cmp.proxy!.open();

  return cmp.proxy!;
}

import { Formatter, Fraction, Note, RenderContext, StaveConnector, StaveNote, Tuplet } from "vexflow";
import Staff from "./Staff";
import * as SheetDrawerSVG from "./SheetDrawerSVG";
import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import { StaveNoteModifier } from "@/model/sheets/StaveNoteModifier";
import IvoryNote from "@/model/songs/IvoryNote";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";

export default class GrandStaff {

  static MIN_STAFF_WIDTH = 0;

  measure1: Staff;
  measure2: Staff;

  x: number;
  y: number;

  width: number | null = null;

  barId: number;

  connectors: StaveConnector[] = [];

  hoverRectangle: SVGRectElement | null = null;

  onClick: Function | null = null;

  constructor(
    barId: number,
    x: number,
    y: number,
    measure1: Staff,
    measure2: Staff,
    onClick: Function
  ) {
    this.x = x;
    this.y = y;
    this.barId = barId;
    this.measure1 = measure1;
    this.measure2 = measure2;
    this.onClick = onClick;
  }

  bindEvents() {
    this.measure1.bindEvents();
    this.measure2.bindEvents();


  }



  build(context: RenderContext) {

    this.buildConnectors(context);
    this.buildTriplets(context);



  }
  buildTriplets(context: RenderContext) {
    [this.measure1, this.measure2].forEach((measure) => {
      const notes = measure.voice.getTickables() as Note[];
      const ivoryNotes = measure.ivoryMesure.notes;
      let currentGroup: Note[] = [];

      for (let i = 0; i < ivoryNotes.length; i++) {
        const ivoryNote = ivoryNotes[i];
        if (ivoryNote.modifiers.includes(StaveNoteModifier.Triplet)) {
          currentGroup.push(notes[i]);

          if (currentGroup.length === 3) {

            const tp = new Tuplet(currentGroup, {
              bracketed: true,
              numNotes: 3,
              notesOccupied: 2,
              ratioed: false,
            });


            measure.tuplets.push(tp);
            tp.setContext(context).attach();

            currentGroup = [];
          }
        }
      }
    });

  }


  buildConnectors(context: RenderContext) {
    if (this.x == 0) {
      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(3);

      brace.setContext(context);

      this.connectors.push(brace);

      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(1);

      brace.setContext(context);

      this.connectors.push(brace);
    }

    if (this.x == SheetDrawerSVG.CurrentStavePerLine - 1) {
      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(0);
      brace.setContext(context);
      this.connectors.push(brace);
    }
  }
  format() {
    const formatter = new Formatter();
    formatter.joinVoices([this.measure1.voice, this.measure2.voice]);

    var justifyWidth = GrandStaff.MIN_STAFF_WIDTH;

    var staveWidth = GrandStaff.MIN_STAFF_WIDTH;

    var modifierX = Math.max(
      this.measure1.stave.getModifierXShift(),
      this.measure2.stave.getModifierXShift()
    );

    var offset = 20;

    justifyWidth -= modifierX + offset;

    try {
      formatter.format(
        [this.measure1.voice, this.measure2.voice],
        justifyWidth, { alignRests: false, }
      );
    } catch {
      console.log(this.barId + "error");
    }

    this.measure1.stave.setWidth(staveWidth);
    this.measure2.stave.setWidth(staveWidth);


    if (this.x > 0) {

      if (this.measure1.ivoryMesure.staveChange != null) {
        this.measure2.stave.setNoteStartX(this.measure2.stave.getNoteStartX() + SheetDrawerSVG.KEY_CHANGE_WIDTH_PX);
      }

      if (this.measure2.ivoryMesure.staveChange != null) {
        this.measure1.stave.setNoteStartX(this.measure1.stave.getNoteStartX() + SheetDrawerSVG.KEY_CHANGE_WIDTH_PX);
      }
    }

    this.width = staveWidth;
  }

  createHoverRectangle(ctx: any) {
    const svg = ctx.svg;

    this.hoverRectangle = document.createElementNS("http://www.w3.org/2000/svg", "rect");

    this.hoverRectangle.setAttribute("x", this.measure1.stave.getX().toString());
    this.hoverRectangle.setAttribute("y", this.measure1.initialY.toString());
    this.hoverRectangle.setAttribute("width", this.measure1.stave.getWidth().toString());
    this.hoverRectangle.setAttribute("height", (this.measure1.stave.getHeight() + SheetDrawerSVG.GAP_BETWEEN_STAFF).toString());

    this.hoverRectangle.setAttribute("fill", "transparent");
    this.hoverRectangle.setAttribute("stroke", "none"); // No border.
    this.hoverRectangle.style.cursor = "pointer";
    this.hoverRectangle.setAttribute("zIndex", "99999");
    this.hoverRectangle.style.pointerEvents = "all";
    this.hoverRectangle.setAttribute("pointer-events", "all");


    this.hoverRectangle.setAttribute("rx", "12"); // Adjust the value as needed.
    this.hoverRectangle.setAttribute("ry", "12");


    this.hoverRectangle.addEventListener("mouseenter", () => {
      this.hoverRectangle!.setAttribute("fill", "rgba(255,0,0,0.2)");
    });

    this.hoverRectangle.addEventListener("mouseleave", () => {
      this.hoverRectangle!.setAttribute("fill", "transparent");
    });

    this.hoverRectangle.addEventListener("click", () => {


      var index = -1;

      var note = this.measure1.ivoryMesure.notes.find(x => !x.isRest());

      if (note) {
        index = note.indexes[0];
      }
      else {
        note = this.measure2.ivoryMesure.notes.find(x => !x.isRest());
        index = note!.indexes[0];
      }

      this.onClick!(this.barId, index);
    });


    svg.appendChild(this.hoverRectangle);
  }

  draw(ctx: any) {
    this.measure1.draw(ctx);
    this.measure2.draw(ctx);

    for (let connectors of this.connectors) {
      connectors.draw();
    }


    this.createHoverRectangle(ctx);
  }
}

import { KeyColor } from "@/model/KeyColor";
import * as Drawing from "@/graphics/DrawingHelper";
import { getState } from "@/state/IvoryState";
import * as Constants from "@/Constants";
import IvoryNote from "@/model/songs/IvoryNote";
import * as NoteManager from "@/managers/NoteManager";
import { PlayerState } from "@/state/PlayerState";
import * as ColorUtils from "@/utils/ColorUtils";
import * as Snackbars from "@/utils/Snackbars";

export default class FallingNote {

  public data: IvoryNote;
  public x: number = 0;
  public y: number = 0;
  public color: string = "";

  public textWidth: number | null = null;
  public textColor: string | null = null;

  public reached: boolean = false;
  public inputRemoved: boolean = false;
  public removed: boolean = false;
  public visible: boolean = true;
  public selected: boolean = false;

  private state: any = null;

  constructor(data: IvoryNote) {
    this.data = data;
    this.state = getState();
  }

  public getWidth(): number {
    const keyColor = NoteManager.getKeyColor(this.data.number);
    return keyColor === KeyColor.White
      ? this.state.whiteKeyWidth
      : this.state.blackKeyWidth;
  }

  public getHeight(): number {
    return Math.max(PlayerState.NoteHeightSecond * this.data.duration, 5);
  }

  public getRealY(): number {
    const realHeight = Math.max(
      PlayerState.NoteHeightSecond * this.data.realDuration,
      5
    );
    return this.y - realHeight;
  }

  public getColor() {
    return this.data.leftHand
      ? this.state.getNoteColorLHHex()
      : this.state.getNoteColorHex();
  }

  public displayed(displayRight: boolean, displayLeft: boolean) {
    return this.data.leftHand ? displayLeft : displayRight;
  }

  public draw(ctx: CanvasRenderingContext2D) {
    // Cache values that are repeatedly used
    const baseColor = this.getColor();
    const keyColor = NoteManager.getKeyColor(this.data.number);
    let height = this.getHeight();

    // Convert baseColor to RGB once
    const rgb = ColorUtils.hexToRgb(baseColor)!;

    // Compute ratio for the color adjustments once
    const halfHeight = height / 2;
    const n = Math.max(0, this.y + halfHeight);
    const ratio = n / 100;
    const minValue = 20;

    // Compute delta color values once
    let deltaR = Math.max(
      Math.min(rgb.r, (rgb.r - minValue) * ratio + minValue),
      0
    );
    let deltaG = Math.max(
      Math.min(rgb.g, (rgb.g - minValue) * ratio + minValue),
      0
    );
    let deltaB = Math.max(
      Math.min(rgb.b, (rgb.b - minValue) * ratio + minValue),
      0
    );

    const noteFlowHeight = this.state.noteFlow?.getHeight()!;
    let val = (this.y + height) / (noteFlowHeight / 3);
    val = Math.min(1, val);
    val = 1 - val;

    // Start with adjusted color
    this.color = baseColor;

    // Adjusting the note color based on key color
    if (keyColor == KeyColor.White) {
      this.color = ColorUtils.brightenColor(this.color, 10);
    } else {
      this.color = ColorUtils.darkenColor(this.color, 10);
    }

    // Darken the color further based on val
    this.color = ColorUtils.darkenColor(this.color, val * 100);

    let shadowIntensity = PlayerState.NoteShadowIntensity;

    // If note is reached or selected, brighten color differently
    if (this.reached) {
      const brightenAmount = Math.round(
        this.state.keyBrightnessMultiplier * 100 - 100
      );
      this.color = ColorUtils.brightenColor(
        ColorUtils.rgbToHex(deltaR, deltaG, deltaB),
        brightenAmount
      );
      shadowIntensity = 1;
    }

    if (this.selected) {
      const brightenAmount = Math.round(
        this.state.keyBrightnessMultiplier * 100 - 100
      );
      this.color = ColorUtils.brightenColor(
        ColorUtils.rgbToHex(rgb.r, rgb.g, rgb.b),
        brightenAmount
      );
      shadowIntensity = 1;
    }

    // Set shadow properties once
    ctx.shadowColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${shadowIntensity})`;
    ctx.shadowBlur = PlayerState.NoteShadowBlur;
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 1;

    ctx.fillStyle = this.color;
    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgba(25,25,25,255)";

    let noteBorderRadius: number = 10;

    const effectiveWidth = window.innerWidth * window.devicePixelRatio;

    if (this.state.isMobileVersion()) {
      noteBorderRadius = 7;
    } else {
      if (effectiveWidth > 2000) {
        noteBorderRadius = 15;
      }
      if (effectiveWidth > 4000) {
        noteBorderRadius = 17;
      }
      if (effectiveWidth > 6000) {
        noteBorderRadius = 25;
      }
      if (effectiveWidth > 8000) {
        noteBorderRadius = 40;
      }
    }

    if (keyColor == KeyColor.Black) {
      noteBorderRadius /= 2;
    }

    if (height < 20) {
      height = 15;
      noteBorderRadius = 8;
    }



    // Draw main rounded rect
    Drawing.drawRoundedRect(
      ctx,
      this.x,
      this.y,
      this.getWidth(),
      height,
      noteBorderRadius
    );

    // Draw pedal tailing if enabled
    if (this.state.settings.displayPedalTailing) {
      const realHeight = PlayerState.NoteHeightSecond * this.data.realDuration;
      const diff = realHeight - this.getHeight();
      if (diff > 5) {
        Drawing.drawRect(
          ctx,
          this.x,
          this.y - diff,
          this.getWidth(),
          realHeight,
          "rgba(10,10,10,0.05)",
          true,
          "rgba(120,120,120,0.4 )"
        );
      }
    }

    // Draw note name if enabled
    if (this.state.settings.displayNoteNames) {
      const noteName = NoteManager.getNote(this.data.number);

      if (this.textColor == null) {
        this.textColor = ColorUtils.getReadableComplement(baseColor);
      }
      ctx.fillStyle = this.textColor;

      ctx.textBaseline = "middle";

      if (this.textWidth == null) {
        let textMetrics = ctx.measureText(noteName);
        this.textWidth = textMetrics.width;
      }

      let textX = this.x + (this.getWidth() - this.textWidth) / 2;
      let textY = this.y + height - 15;

      ctx.fillText(noteName, textX, textY);
    }


    if (this.state.account != null && this.state.account.role == 5) {
      if (this.data.downbeat) {
        var color = "rgba(255,0,0,0.7)";

        ctx.lineWidth = 5;
        ctx.strokeStyle = color;

        ctx.strokeRect(this.x, this.y, this.getWidth(), height);

        ctx.lineWidth = 2;
        Drawing.drawLine(
          ctx,
          0,
          this.y + height,
          this.state.getKeyboardWidth(),
          this.y + height,
          color
        );
      }


    }
  }
}

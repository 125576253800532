
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";
import SearchResults from "@/model/SearchResults";
import IvorySong from "@/model/songs/IvorySong";
import * as ColorUtils from "@/utils/ColorUtils";
import { getHomeState } from "@/state/HomeState";
import SearchPage from "@/components/home/SearchPage.vue";
import TranscriptionPage from "@/components/home/TranscriptionPage.vue";
import HomeBar from "@/components/HomeBar.vue";
import { HomePageEnum } from "@/state/HomePageEnum";
import ChordsPage from "@/components/home/ChordsPage.vue";
import ProfilePage from "@/components/home/ProfilePage.vue";
import * as Modals from "@/utils/Modals";
import HomePage from "@/components/home/HomePage.vue";
import * as Constants from "@/Constants";

export default defineComponent({
  components: {
    HalfCircleSpinner,
    SearchPage,
    TranscriptionPage,
    ProfilePage,
    ChordsPage,
    HomePage,
  },

  computed: {
    container(): any {
      var bg = Constants.DefaultBackground;

      if (
        this.appState.backgroundUrl &&
        this.appState.backgroundUrl.trim() != ""
      ) {
        bg = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${this.appState.backgroundUrl})`;
      }

      return {
        backgroundImage: bg,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },

  mounted() { },

  methods: {},
  data() {
    return {
      HomePageEnum: HomePageEnum,
      state: getHomeState(),
      appState: getState(),
    };
  },
});

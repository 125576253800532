import { getState } from "@/state/IvoryState";
import FallingNote from "./FallingNote";
import * as NoteManager from "@/managers/NoteManager";
import { Midi } from "@tonejs/midi";
import { KeyColor } from "@/model/KeyColor";
import IvorySong from "@/model/songs/IvorySong";
import * as Constants from "@/Constants";
import * as TimeUtils from "@/utils/TimeUtils";
import * as ChordManager from "@/managers/ChordManager";
import IvoryNote from "@/model/songs/IvoryNote";
import * as Drawing from "@/graphics/DrawingHelper";
import * as SoundManager from "@/managers/SoundManager";
import { PlayerState } from "@/state/PlayerState";
import * as Modals from "@/utils/Modals";
import * as IvoryApi from "@/IvoryApi";
import IvoryChord from "@/model/songs/IvoryChord";
import * as Snackbars from "@/utils/Snackbars";

export default class NoteFlow {
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  private selectionStartX: number | null = null;

  private selectionStartY: number | null = null;

  private mouseX: number = 0;
  private mouseY: number = 0;

  private mouseDown: boolean = false;

  private notes: FallingNote[] = [];

  private paused: boolean = false;

  public song: IvorySong | null = null;

  private lastDownbeatTimeSig = 0;

  public timeCurrent = 0;

  private lastTimestamp = 0;

  private destroyed = false;

  private lastTimeCurrent = 0;

  private state: any;

  private transpose: number = 0;

  private wheel: boolean = false;

  private wheelEventEndTimeout: number | null = null;

  private deltaTime: number | null = null;

  private progressiveMove: boolean = false;

  private progressiveInterval: number | null = null;

  private ProgressiveMultiplierDefault: number = 1.5;

  private progressiveMultiplier: number = this.ProgressiveMultiplierDefault;

  private progressiveDirection: number = 1;

  private touchStartY: number | null = null;
  private touchCurrentY: number | null = null;
  private touchSign: number | null = null;

  private chords: IvoryChord[] = [];

  public editMode: boolean = false;

  progressivePlayback(direction: number) {
    this.progressiveMove = true;
    this.progressiveDirection = direction;

    if (this.progressiveInterval != null) {
      clearInterval(this.progressiveInterval);
    }

    this.progressiveInterval = setInterval(() => {
      this.progressiveMove = false;
      this.progressiveMultiplier = this.ProgressiveMultiplierDefault;
      this.progressiveDirection = 1;
    }, 100);
  }

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;

    this.ctx = canvas.getContext("2d")!;

    this.state = getState();

    this.canvas.addEventListener("mousemove", this.onMouseMove);

    this.canvas.addEventListener("mousedown", this.onMouseDown);

    this.canvas.addEventListener("mouseup", this.onMouseUp);

    window.addEventListener("keydown", this.onKeyDown);

    this.canvas.addEventListener("wheel", this.onMouseWheel);

    this.canvas.addEventListener("click", this.onClick);
    this.canvas.addEventListener("contextmenu", this.onRightClick);

    this.canvas.addEventListener("touchstart", this.onTouchStart);
    this.canvas.addEventListener("touchmove", this.onTouchMove);
    this.canvas.addEventListener("touchend", this.onTouchEnd);

    this.update(0);
  }

  onKeyDown = async (e: KeyboardEvent) => {

    if (this.editMode && this.state.account.role == 5) {
      var selectedNotes = this.notes
        .filter((x) => x.selected)
        .sort((a, b) => a.data.start - b.data.start);


      if (selectedNotes.length == 1 && selectedNotes[0].data.downbeat) {

        if (e.code == "Digit1") {
          selectedNotes[0].data.downbeatTimeSignature = 4;

        }
        else if (e.code == "Digit3") {
          selectedNotes[0].data.downbeatTimeSignature = 3;
        }
        else if (e.code == "Digit2") {
          selectedNotes[0].data.downbeatTimeSignature = 2;
        }

        this.lastDownbeatTimeSig = selectedNotes[0].data.downbeatTimeSignature;
      }
    }

  };

  onMouseUp = (e: MouseEvent) => {
    this.selectionStartX = null;
    this.selectionStartY = null;

    this.mouseDown = false;

    if (e.button == 0) {
      const isShiftPressed = e.shiftKey;

      var selectedNotes = this.notes
        .filter((x) => x.selected)
        .sort((a, b) => a.data.start - b.data.start);

      for (const note of selectedNotes) {
        if (this.editMode) {


          if (isShiftPressed && this.state.account.role == 5) {
            note.data.downbeat = !note.data.downbeat;
            note.data.downbeatTimeSignature = this.lastDownbeatTimeSig;
          } else {
            note.data.leftHand = !note.data.leftHand;
          }
        }

        SoundManager.play(note.data.number, note.data.velocity);
      }
    } else if (e.button == 2) {
      for (var note of this.notes.filter((x) => x.selected)) {
        if (this.editMode) {
          this.notes.splice(this.notes.indexOf(note), 1);
          this.song?.data?.notes.splice(
            this.song?.data?.notes.indexOf(note.data),
            1
          );
          this.state.keyboard.removeInputs();
        }

        SoundManager.play(note.data.number, note.data.velocity);
      }
    }
  };
  onMouseDown = (e: MouseEvent) => {
    const rect = this.canvas.getBoundingClientRect();

    this.selectionStartX = e.clientX - rect.left;
    this.selectionStartY = e.clientY - rect.top;

    this.mouseDown = true;
  };

  onRightClick = async (e: MouseEvent) => {
    e.preventDefault();
  };
  onClick = async (e: MouseEvent) => { };

  onMouseMove = (e: any) => {
    const rect = this.canvas.getBoundingClientRect();
    this.mouseX = e.clientX - rect.left;
    this.mouseY = e.clientY - rect.top;
  };

  onMouseWheel = (e: WheelEvent) => {
    this.slide(e.deltaY / 300);
  };

  onTouchStart = (e: TouchEvent) => {
    console.log("touch start");
    this.touchStartY = e.touches[0].clientY;
    this.touchCurrentY = this.touchStartY;
  };

  onTouchMove = (e: TouchEvent) => {
    if (this.touchStartY === null) return;

    if (this.touchCurrentY != null) {
      let sign = Math.sign(e.touches[0].clientY - this.touchCurrentY);

      if (this.touchSign != null && sign != this.touchSign) {
        this.touchStartY = e.touches[0].clientY;
      }
      this.touchSign = sign;
    }

    this.touchCurrentY = e.touches[0].clientY;

    let deltaY = this.touchStartY - this.touchCurrentY;

    var timeDelta = deltaY / 2500;

    this.setPause(true);

    this.slide(timeDelta);
  };

  slide(timeDelta: number) {
    if (this.song == null) {
      return;
    }

    if (Math.abs(timeDelta) < this.deltaTime! * this.state.timeMultiplier) {
      this.wheel = false;
      return;
    }
    if (this.timeCurrent - timeDelta < 0) {
      return;
    }

    if (this.getPlaybackTime() - timeDelta > this.song?.data!.totalDuration!) {
      return;
    }

    this.timeCurrent -= timeDelta;

    for (let note of this.notes) {
      if (timeDelta > 0 && note.reached && !note.removed) {
        this.state.keyboard!.removeSound(note.data.number);
      }
      note.y -= timeDelta * PlayerState.NoteHeightSecond;
    }

    this.wheel = true;

    if (this.wheelEventEndTimeout != null) {
      clearTimeout(this.wheelEventEndTimeout!);
    }

    this.wheelEventEndTimeout = setTimeout(() => {
      this.wheel = false;
    }, 5);
  }
  onTouchEnd = (e: TouchEvent) => {
    if (this.touchSign == null) {
      this.togglePause();
    }
    this.touchStartY = null;
    this.touchCurrentY = null;
    this.touchSign = null;

    this.wheel = false;
  };

  update = (timestamp: number) => {
    if (this.destroyed) {
      return;
    }

    var play = true;

    if (PlayerState.NoteShadowIntensity <= 50) {
      PlayerState.NoteShadowIntensity += 0.1;
    }

    if (Math.abs(this.timeCurrent - this.lastTimeCurrent) > 0.2) {
      play = false;
    }

    if (this.state.loopEnd != null) {
      if (
        this.lastTimeCurrent < this.state.loopEnd &&
        this.timeCurrent >= this.state.loopEnd &&
        !this.wheel
      ) {
        this.timeCurrent = this.state.loopStart - 0.2;
      }
    }

    this.lastTimeCurrent = this.timeCurrent;

    if (
      this.song != null &&
      this.getPlaybackTime() >= this.song!.data!.totalDuration
    ) {
      if (!this.paused) {
        if (!this.state.loggedIn() || !this.state.account.subscribed) {

          Modals.open(
            "You are not subscribed to the service. You are currently limited to <b>30 seconds</b> of playback and transcription. Subscribe to the service to get <b>unlimited access to all features.</b>.",
            "Subscription required"
          );

        } else {
          Snackbars.display(
            "We want to provide our users with the best content available. Feel free to click on the stars to rate the quality of the transcription.",
            10000
          );
        }
      }
      this.setPause(true);
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (!this.lastTimestamp) {
      this.lastTimestamp = timestamp;
    }

    let displayRight = this.state.displayRightHand;
    let displayLeft = this.state.displayLeftHand;

    this.deltaTime = (timestamp - this.lastTimestamp) / 1000; // Convert to seconds
    this.lastTimestamp = timestamp;

    if (this.deltaTime > 0.1) {
      this.deltaTime = 0;
    }

    let tailing = this.state.settings.displayPedalTailing;

    var fps = Math.round(1 / this.deltaTime);

    //Drawing.drawText(this.ctx, fps + " FPS", 15, 15);

    if (!this.paused && !this.wheel && !this.progressiveMove) {
      this.timeCurrent += this.deltaTime * this.state.timeMultiplier; // Add delta time to timeCurrent
    }

    if (this.progressiveMove) {
      this.timeCurrent +=
        this.deltaTime! *
        this.progressiveMultiplier *
        this.progressiveDirection;

      this.progressiveMultiplier += 0.02;
    }

    if (this.song != null) {
      var header = this.state.getHeader();

      header?.$forceUpdate();
    }

    this.ctx.font = "bold 20px Kanit";

    var admin = this.state.account && this.state.account.role == 5;

    for (let i = 0; i < this.notes.length; i++) {
      var note = this.notes[i];

      let boundY = tailing ? note.getRealY() : note.y;

      note.visible =
        note.y + note.getHeight() >= 0 &&
        boundY <= this.getHeight() &&
        note.x > 0;

      if (note.visible && !note.removed) {
        if (note.displayed(displayRight, displayLeft)) {
          note.draw(this.ctx);

          if (note.data.downbeat && admin) {
            this.ctx.fillStyle = "white";

            var previous = this.notes
              .slice()
              .reverse()
              .find((x) => x.data.start < note.data.start && x.data.downbeat);

            if (previous) {
              var diff = note.data.start - previous!.data.start;

              var tempo = Math.round((60 * 4) / diff);


              this.ctx.font = "normal 40px Kanit";

              this.ctx.fillText(
                tempo + " BPM",
                40,
                note.y + note.getHeight() + 40
              );


            }

            if (admin) {

              this.ctx.font = "normal 20px Kanit";

              this.ctx.fillText(
                "Time sig : " + note.data.downbeatTimeSignature,
                40,
                note.y + note.getHeight() + 60
              );
              this.ctx.font = "bold 20px Kanit";
            }


          }
        }
      }

      // if (!this.paused) {
      var iY =
        -note.data.start * PlayerState.NoteHeightSecond - note.getHeight();
      note.y = iY + this.timeCurrent * PlayerState.NoteHeightSecond;
      //     }

      if (note.getRealY() < this.getHeight() && note.removed) {
        note.removed = false;
      }

      if (note.removed) {
        continue;
      }

      if (!note.reached && note.y + note.getHeight() >= this.getHeight()) {
        if (note.displayed(displayRight, displayLeft)) {
          this.state.keyboard!.addInput(
            note.data.number,
            play,
            note.data.velocity,
            note.data.leftHand
          );
        }
        note.reached = true;

        if (this.state.learningMode.active) {
          this.state.learningMode.pendingNotes.push(note.data);
          this.setPause(true);
        }
      }

      if (note.reached && note.y >= this.getHeight()) {
        if (!note.inputRemoved) {
          this.state.keyboard!.removeInput(note.data.number);
          note.inputRemoved = true;
        }

        if (note.getRealY() >= this.getHeight()) {
          note.removed = true;
          this.state.keyboard!.removeSound(note.data.number);
        }
      }

      if (
        note.reached &&
        note.y <= this.getHeight() - note.getHeight() &&
        !note.selected
      ) {
        note.reached = false;
        note.inputRemoved = false;

        this.state.keyboard!.removeInput(note.data.number);
      }
    }

    if (this.state.settings.displayNoteLines) {
      this.drawNoteLines();
    }

    if (this.song != null) {
      if (this.state.settings.displayChords) {
        this.drawChords(this.state);
      }

      this.drawLoop();

      if (this.editMode) {
        let color = "white";
        let text = "Edit mode (unsaved)";

        var y = this.getHeight();
        var x = 0;
        this.ctx.shadowColor = color;

        Drawing.drawText(this.ctx, text, x + 15, y - 15, "18px");
      }
    }

    if (this.song != null && this.paused && !this.state.isMobileVersion()) {
      if (this.editMode) {
        for (let i = 0; i < this.notes.length; i++) {
          var note = this.notes[i];

          let canSelect = true;

          if (
            canSelect &&
            note.y <= this.mouseY &&
            note.y + note.getHeight() >= this.mouseY &&
            note.x <= this.mouseX &&
            note.x + note.getWidth() >= this.mouseX
          ) {
            this.drawNoteLine(note);

            if (!note.selected && this.mouseY >= note.y) {
              note.selected = true;
            }
          } else {
            note.selected = false;
          }
        }
      } else {
        this.ctx.beginPath();
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(0, this.mouseY);
        this.ctx.lineTo(this.canvas.width, this.mouseY);
        this.ctx.strokeStyle = "rgba(255,255,255,0.1)";
        this.ctx.lineWidth = 5;
        this.ctx.shadowColor = "rgba(255,255,255, 0.5)";
        this.ctx.shadowOffsetX = 0;
        this.ctx.shadowOffsetY = 0;
        this.ctx.stroke();

        for (let i = 0; i < this.notes.length; i++) {
          var note = this.notes[i];

          if (
            note.y <= this.mouseY &&
            note.y + note.getHeight() >= this.mouseY
          ) {
            this.drawNoteLine(note);

            if (!note.selected && this.mouseY >= note.y) {
              note.selected = true;
            }
          } else {
            note.selected = false;
          }
        }
      }
    }

    this.drawSelectionRectangle();

    window.requestAnimationFrame(this.update);
  };
  drawSelectionRectangle() {
    if (!this.editMode) {
      return;
    }

    if (this.selectionStartX == null || this.selectionStartY == null) {
      return;
    }

    // Current mouse position
    const currentX = this.mouseX;
    const currentY = this.mouseY;

    // 1. Normalize the selection rectangle coordinates
    const left = Math.min(this.selectionStartX, currentX);
    const right = Math.max(this.selectionStartX, currentX);
    const top = Math.min(this.selectionStartY, currentY);
    const bottom = Math.max(this.selectionStartY, currentY);

    // 2. Draw the rectangle
    const width = right - left;
    const height = bottom - top;

    this.ctx.beginPath();
    this.ctx.strokeStyle = "white";
    this.ctx.lineWidth = 2;
    this.ctx.rect(left, top, width, height);
    this.ctx.fillStyle = "rgba(150,150,150,0.05)";
    this.ctx.fillRect(left, top, width, height);
    this.ctx.stroke();

    // 3. Check overlap for each note
    for (let note of this.notes.filter((x) => x.visible)) {
      const noteLeft = note.x;
      const noteRight = note.x + note.getWidth();
      const noteTop = note.y;
      const noteBottom = note.y + note.getHeight();

      // Check if the note’s bounding box intersects with the selection rectangle
      const overlaps =
        left < noteRight &&
        right > noteLeft &&
        top < noteBottom &&
        bottom > noteTop;

      if (overlaps) {
        this.drawNoteLine(note);
        if (!note.selected) {
          note.selected = true;
        }
      } else {
        note.selected = false;
      }
    }
  }

  public stop() {
    for (let note of this.notes) {
      if (note.reached && !note.removed) {
        this.state.keyboard!.removeSound(note.data.number);
      }
    }

    this.clearNotes();
    this.song = null;

    this.state.keyboard.removeInputs();
  }
  getPlaybackTime() {
    var frameDuration = this.getHeight() / PlayerState.NoteHeightSecond;
    return this.timeCurrent - frameDuration;
  }
  drawChords(state: any) {
    for (var chord of this.chords!) {
      let leftHand = chord.notes[0].leftHand;

      if (leftHand && !state.displayLeftHand) {
        continue;
      }
      if (!leftHand && !state.displayRightHand) {
        continue;
      }
      var iY = -chord.start * PlayerState.NoteHeightSecond;

      var y = iY + this.timeCurrent * PlayerState.NoteHeightSecond;

      var height = (chord.end - chord.start) * PlayerState.NoteHeightSecond;

      y -= height;

      var x = 0;

      if (y + height >= 0 && y <= this.getHeight()) {
        this.ctx.lineWidth = 2;

        var color = state.getNoteColorHTML(10);

        if (leftHand) {
          color = state.getNoteColorLHHTML(10);
        }

        this.ctx.shadowColor = color;
        Drawing.drawLine(
          this.ctx,
          x,
          y + height,
          state.getKeyboardWidth(),
          y + height,
          color
        );

        Drawing.drawText(
          this.ctx,
          chord.name!,
          x + 15,
          y + height - 15,
          "22px"
        );

        if (height > 5)
          Drawing.drawRect(
            this.ctx,
            x,
            y,
            state.getKeyboardWidth(),
            height,
            "rgba(255,255,255,0.05)",
            false
          );
      }
    }
  }
  drawLoop() {
    // '#3deb34' = vert
    if (this.state.loopStart != null) {
      if (this.state.loopEnd != null) {
        this.drawAnnotation(
          "Loop start",
          this.state.loopStart,
          this.state.getNoteColorHex()
        );
        this.drawAnnotation(
          "Loop end",
          this.state.loopEnd,
          this.state.getNoteColorLHHex()
        );
        this.drawRect(
          this.state.loopStart,
          this.state.loopEnd - this.state.loopStart,
          this.state.getNoteColorLHHex(),
          0.02
        );
      } else {
        this.drawAnnotation(
          "Loop start",
          this.state.loopStart,
          this.state.getNoteColorHex()
        );
      }
    }
  }
  drawRect(
    time: number,
    duration: number,
    color: string,
    alpha: number = 0.15
  ) {
    var height = duration * PlayerState.NoteHeightSecond;
    var iY = -time * PlayerState.NoteHeightSecond;
    var delta = this.getHeight() / PlayerState.NoteHeightSecond;
    var y = iY + (this.timeCurrent + delta) * PlayerState.NoteHeightSecond;
    var x = 0;
    this.ctx.shadowColor = color;
    Drawing.drawRect(
      this.ctx,
      x,
      y - height,
      this.state.getKeyboardWidth(),
      height,
      "rgba(120,120,120," + alpha + ")"
    );
  }
  drawAnnotation(text: string, time: number, color: string) {
    var iY = -time * PlayerState.NoteHeightSecond;
    var delta = this.getHeight() / PlayerState.NoteHeightSecond;
    var y = iY + (this.timeCurrent + delta) * PlayerState.NoteHeightSecond;
    var x = 0;
    this.ctx.shadowColor = color;
    Drawing.drawLine(this.ctx, x, y, this.state.getKeyboardWidth(), y, color);
    Drawing.drawText(this.ctx, text, x + 15, y - 15, "22px");
  }
  drawNoteLines() {
    var coveredNumbers = [] as number[];

    for (var note of this.notes) {
      if (
        coveredNumbers.includes(note.data.number) ||
        note.reached ||
        note.removed ||
        !note.visible
      ) {
        continue;
      }

      this.drawNoteLine(note);
      coveredNumbers.push(note.data.number);
    }
  }
  drawNoteLine(note: FallingNote) {
    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    this.ctx.moveTo(note.x + note.getWidth() / 2, this.canvas.height);
    this.ctx.lineTo(
      note.x + note.getWidth() / 2,
      note.y + note.getHeight() / 2
    );
    this.ctx.strokeStyle = note.color;
    this.ctx.stroke();
  }
  addNote(ivoryNote: IvoryNote) {
    var note = new FallingNote(ivoryNote);

    note.x = this.state.keyboard!.getKeyX(ivoryNote.number);

    note.y = -ivoryNote.start * PlayerState.NoteHeightSecond - note.getHeight();

    this.notes.push(note);
  }

  recomputeNoteX() {
    for (let note of this.notes) {
      note.x = this.state.keyboard!.getKeyX(note.data.number);
    }
  }

  clearNotes() {
    this.notes = [];
  }
  getNotes() {
    return this.notes;
  }

  computeChords() {
    if (this.chords.length > 0) {
      return;
    }

    for (let chord of this.song!.data!.chords) {
      var notes = chord
        .map((x) => this.song!.data!.notes.find((y) => y.index == x)!)
        .sort((a, b) => a.start - b.start);

      var noteIds = notes.map((x) => x!.number + 1);

      if (noteIds.length > 8) {
        continue;
      }
      var name = ChordManager.getChordNameFromIds(noteIds, true);

      if (name != null) {
        var ivoryChord = new IvoryChord();
        ivoryChord.name = name != null ? name : "Unknown";
        ivoryChord.notes = notes;
        ivoryChord.start = notes[0].start;
        ivoryChord.end = notes[notes.length - 1].start;

        this.chords.push(ivoryChord);
      }
    }
  }

  loadSong(midiSong: IvorySong) {
    for (var ivoryNote of midiSong.data!.notes) {
      this.addNote(ivoryNote);
    }

    this.state.displayLeftHand = true;
    this.state.displayRightHand = true;
    this.editMode = false;
    this.song = midiSong;
    this.timeCurrent = 0;
    this.transpose = 0;

    this.computeChords();
  }

  togglePause() {
    if (this.paused) {
      this.setPause(false);
    } else {
      this.setPause(true);
    }
    return this.paused;
  }
  setPause(pause: boolean) {
    this.paused = pause;
    this.state.getHeader().onPauseChange(pause);

    if (this.paused) {
      SoundManager.stop();
    }
    if (!pause) {
      for (let note of this.notes) {
        note.selected = false;
      }
    }
  }

  isPaused() {
    return this.paused;
  }

  destroy() {
    this.destroyed = true;
    this.notes = [];

    if (this.progressiveInterval != null) {
      clearInterval(this.progressiveInterval);
    }
  }

  getHeight() {
    return this.canvas.height;
  }

  getTranspose() {
    return this.transpose;
  }

  public addTranspose(delta: number) {
    for (var note of this.notes) {
      note.data.number += delta;
      note.x = this.state.keyboard!.getKeyX(note.data.number);
    }

    this.transpose += delta;

    for (let chord of this.chords) {
      chord.updateName();
    }
  }
}

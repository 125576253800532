
import { defineComponent } from "vue";
import { getHomeState } from "@/state/HomeState";
import IvoryButton from "../global/IvoryButton.vue";
import NewsCard from "@/components/home/NewsCard.vue";
import { getState } from "@/state/IvoryState";
import { HomePageEnum } from "@/state/HomePageEnum";
import NewsRecord from "@/model/accounts/NewsRecord";
import * as Constants from "@/Constants";
import * as IvoryApi from "@/IvoryApi";

export default defineComponent({
  components: { NewsCard },
  async mounted() {

  },
  data() {
    var state = getHomeState();
    var appState = getState();

    return {
      Constants: Constants,
      state: state,
      HomePageEnum: HomePageEnum,

      appState: appState,
    };
  },

  methods: {},
});

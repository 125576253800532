import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PlayerView from "../views/PlayerView.vue";
import LoadingView from "@/views/LoadingView.vue";
import LoginView from "@/views/LoginView.vue";
import { getState } from "@/state/IvoryState";

import HomeView from "@/views/HomeView.vue";
import SheetView from "@/views/SheetView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/player",
    name: "player",
    component: PlayerView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/load",
    name: "load",
    component: LoadingView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/sheet",
    name: "sheet",
    component: SheetView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  var state = getState();

  if (!state.initialized) {
    if (to.query.id != undefined) {
      state.querySongId = parseInt(to.query.id.toString());
    }
    if (to.query.minimal != undefined && to.query.minimal == "true") {
      state.minimal = true;
    }
  }

  if (!state.initialized && (to.name == "player" || to.name == "home")) {
    next({ name: "load" });
    state.initialized = true;
  } else {
    if (to.name == "home" && state.noteFlow != null) {
      state.noteFlow.destroy();
      state.noteFlow = null;
    }

    next();
  }
});

export default router;

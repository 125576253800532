
import { defineComponent } from "vue";
import * as Snackbars from "@/utils/Snackbars";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "Infos",
    },
    type: {
      type: String,
      default: "info",
      required: false,
    },
  },
  mounted() { },

  methods: {
    removeSnackbar() {
      Snackbars.remove(this.$el.parentElement, 0);
    },
  },
});

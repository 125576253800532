import IvorySong from "@/model/songs/IvorySong";
import IvorySongData from "@/model/songs/IvorySongData";

export default class SongPlayer {
  data: IvorySongData;
  scheduledEvents: any[] = [];

  onNoteStart: Function;
  onNoteEnd: Function;
  onNoteEndReal: Function;

  audioContext: AudioContext | null = null;
  isPlaying: boolean = false;
  schedulerId: number | null = null;

  constructor(
    data: IvorySongData,
    onNoteStart: Function,
    onNoteEnd: Function,
    onNoteEndReal: Function
  ) {
    this.data = data;
    this.onNoteStart = onNoteStart;
    this.onNoteEnd = onNoteEnd;
    this.onNoteEndReal = onNoteEndReal;
  }

  /**
   * Play starting at the note with the given index.
   * - If the song is currently playing, it will “snap” to the new note,
   *   canceling any current scheduled events.
   * - If the song is stopped, playback will start from the specified note.
   */
  playAtNote(noteIndex: number) {

    // Find the note with the matching index.
    const noteToPlay = this.data.notes.find((note) => note.index === noteIndex);
    if (!noteToPlay) {
      console.warn(`Note with index ${noteIndex} not found.`);
      return;
    }

    // If already playing, cancel current scheduler and clear events.
    if (this.isPlaying) {
      if (this.schedulerId !== null) {
        window.cancelAnimationFrame(this.schedulerId);
        this.schedulerId = null;
      }
      this.scheduledEvents = [];
    } else {

      this.audioContext = new window.AudioContext();
      if (this.audioContext.state === "suspended") {
        this.audioContext.resume();
      }
      this.isPlaying = true;
    }

    // Compute a time offset so that noteToPlay starts immediately.
    // In the original play(), we did:
    //    const startTime = audioContext.currentTime;
    //    noteStartTime = startTime + note.start;
    // Here, we want noteToPlay.start to align with the current time.
    const currentTime = this.audioContext!.currentTime;
    const timeOffset = currentTime - noteToPlay.start;

    // Schedule only the notes from the desired note onward.
    const remainingNotes = this.data.notes.filter(
      (note) => note.start >= noteToPlay.start
    );
    for (const note of remainingNotes) {
      const noteStartTime = timeOffset + note.start;
      const noteEndTime = noteStartTime + note.duration;
      const noteRealEndTime = noteStartTime + note.realDuration;

      this.scheduledEvents.push({
        time: noteStartTime,
        type: "start",
        note: note,
      });
      this.scheduledEvents.push({
        time: noteEndTime,
        type: "end",
        note: note,
      });
      this.scheduledEvents.push({
        time: noteRealEndTime,
        type: "realEnd",
        note: note,
      });
    }

    // Sort events by time to ensure correct order.
    this.scheduledEvents.sort((a, b) => a.time - b.time);

    // Start the scheduler loop.
    this.scheduler();

  }

  play() {
    if (this.isPlaying) {
      return;
    }
    this.audioContext = new window.AudioContext();
    // Resume the audio context if it's suspended (browser autoplay policies)
    if (this.audioContext.state === "suspended") {
      this.audioContext.resume();
    }

    this.isPlaying = true;
    const startTime = this.audioContext.currentTime;

    // Schedule all note events
    for (let note of this.data!.notes) {
      const noteStartTime = startTime + note.start;
      const noteEndTime = noteStartTime + note.duration;
      const noteRealEndTime = noteStartTime + note.realDuration;

      this.scheduledEvents.push({
        time: noteStartTime,
        type: "start",
        note: note,
      });

      this.scheduledEvents.push({
        time: noteEndTime,
        type: "end",
        note: note,
      });

      this.scheduledEvents.push({
        time: noteRealEndTime,
        type: "realEnd",
        note: note,
      });
    }

    // Sort events by time to ensure they are processed in order
    this.scheduledEvents.sort((a, b) => a.time - b.time);

    // Start the scheduler loop
    this.scheduler();
  }

  scheduler() {
    if (!this.isPlaying) return;

    const currentTime = this.audioContext!.currentTime;

    // Collect events that need to be executed
    const eventsToExecute: any[] = [];

    // Use a look-ahead time to account for the delay between frames
    const lookAheadTime = 0.01; // 10 milliseconds

    while (
      this.scheduledEvents.length > 0 &&
      this.scheduledEvents[0].time <= currentTime + lookAheadTime
    ) {
      const event = this.scheduledEvents.shift();
      eventsToExecute.push(event);
    }

    // Execute the collected events
    for (const event of eventsToExecute) {
      this.executeEvent(event);
    }

    // Continue the scheduler loop using requestAnimationFrame
    this.schedulerId = window.requestAnimationFrame(this.scheduler.bind(this));
  }

  executeEvent(event: any) {
    if (!this.isPlaying) return;

    switch (event.type) {
      case "start":
        this.onNoteStart(event.note);
        break;
      case "end":
        this.onNoteEnd(event.note);
        break;
      case "realEnd":
        this.onNoteEndReal(event.note);
        break;
    }
  }

  stop() {
    if (!this.isPlaying) {
      return;
    }
    this.isPlaying = false;

    if (this.schedulerId !== null) {
      window.cancelAnimationFrame(this.schedulerId);
      this.schedulerId = null;
    }

    // Clear any pending scheduled events
    this.scheduledEvents = [];

    // Close the audio context to release resources
    if (this.audioContext!.state !== "closed") {
      this.audioContext!.close();
    }
  }
}

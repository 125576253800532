import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5db3b7fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "home-container",
  style: {"padding-bottom":"0px"}
}
const _hoisted_2 = { class: "flex align-center" }
const _hoisted_3 = { class: "item-list" }
const _hoisted_4 = { class: "state-row" }
const _hoisted_5 = {
  key: 1,
  class: "light f16 hbold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!
  const _component_IvoryInput = _resolveComponent("IvoryInput")!
  const _component_SongRow = _resolveComponent("SongRow")!
  const _component_FulfillingBouncingCircleSpinner = _resolveComponent("FulfillingBouncingCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.results.getLength() > 0)
        ? (_openBlock(), _createBlock(_component_IvoryIcon, {
            key: 0,
            onClick: _ctx.back,
            icon: "back"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_IvoryInput, {
        textChange: _ctx.onSearchChange,
        ref: "searchInput",
        modelValue: _ctx.homeState.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.homeState.search) = $event)),
        ready: _ctx.performSearch,
        class: "grow",
        placeholder: "Title, artist",
        icon: "search"
      }, null, 8, ["textChange", "modelValue", "ready"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.songs, (song) => {
        return (_openBlock(), _createBlock(_component_SongRow, {
          onClick: () => _ctx.clickSong(song),
          song: song,
          tailing: true
        }, null, 8, ["onClick", "song"]))
      }), 256)),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.searching)
          ? (_openBlock(), _createBlock(_component_FulfillingBouncingCircleSpinner, {
              key: 0,
              color: "#c4c4c4"
            }))
          : _createCommentVNode("", true),
        (_ctx.resultMessage != '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.resultMessage), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
export default class NewsRecord {
  public id: number = 0;

  public date: Date = new Date();

  public title: string = "";

  public content: string = "";

  public likes: number = 0;

  constructor(
    id: number,
    date: Date,
    title: string,
    content: string,
    likes: number
  ) {
    this.id = id;
    this.date = date;
    this.title = title;
    this.content = content;
    this.likes = likes;
  }

  public static fromJson(x: any) {
    return new NewsRecord(x.id, new Date(x.date), x.title, x.content, x.likes);
  }
}

export default class IvoryTimeSignature {

  public barId: number = 0;

  public beatNoteValue: number = 0;

  public numberOfBeatPerMeasure: number = 0;


  constructor(barId: number, beatNoteValue: number, numberOfBeatPerMeasure: number) {
    this.barId = barId;
    this.beatNoteValue = beatNoteValue;
    this.numberOfBeatPerMeasure = numberOfBeatPerMeasure;
  }

  static fromJson(x: any) {
    return new IvoryTimeSignature(x.barId, x.beatNoteValue, x.numberOfBeatPerMeasure);
  }
}


import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import SongRow from "./SongRow.vue";
import router from "@/router";
import ProcessingTask from "@/model/ProcessingTask";
import TranscriptionRow from "@/components/home/TranscriptionRow.vue";
import TranscriptionResult, {
  TranscriptionStatus,
} from "@/model/TranscriptionResult";
import { getHomeState } from "@/state/HomeState";
import { TranscriptionSource } from "@/model/TranscriptionSource";
import * as Modals from "@/utils/Modals";
import mixpanel from "mixpanel-browser";
import { HomePageEnum } from "@/state/HomePageEnum";
import IvoryButton from "../global/IvoryButton.vue";
import * as Youtube from "@/utils/Youtube";
import IvoryModal from "../global/IvoryModal.vue";
import IvoryCheckbox from "../global/IvoryCheckbox.vue";
import * as Snackbars from "@/utils/Snackbars";

export default defineComponent({
  data() {
    var state = getState();
    var homeState = getHomeState();

    return {
      TranscriptionSource: TranscriptionSource,
      tasks: [] as ProcessingTask[] | null,
      state: state,
      HomePageEnum: HomePageEnum,
      Youtube: Youtube,
      homeState: homeState,
      interval: null as number | null,
      transcriptionSource: null as TranscriptionSource | null,
      myUploads: null as IvorySong[] | null,
      displayed: null as IvorySong[] | null,
      favoritesFilter: false,

    };
  },

  async mounted() {
    if (this.state.loggedIn()) {
      this.interval = setInterval(this.updateTasks, 1000);
      this.updateTasks();
      this.updateUploads();
    }

    if (this.homeState.transcriptionUrl != "") {
      this.onYoutubeReady();
    }
  },
  unmounted() {
    if (this.interval != null) {
      clearInterval(this.interval!);
    }
  },
  methods: {

    onSongDeleted(song: IvorySong) {
      var index = this.myUploads?.indexOf(song);

      this.myUploads?.splice(index!, 1);

      this.updateDisplayed();
    },
    onSearchInput() {
      this.displayed = null;
    },

    async updateUploads() {

      this.myUploads = await IvoryApi.getMyUploads();
      this.updateDisplayed();
    },
    updateDisplayed() {
      var search = this.homeState.search.toLowerCase().trim();
      this.displayed = this.myUploads?.filter(x => x.name.toLowerCase().trim().includes(search))!;

      if (this.favoritesFilter) {
        this.displayed = this.displayed.filter(x => x.isFavorite);
      }
    },

    performSearch() {
      this.updateDisplayed();
    },

    subscribeClick() {
      getHomeState().navigate(HomePageEnum.Profile);
    },
    async removeSong(song: IvorySong) {
      song.pendingRemove = true;
      await IvoryApi.removeSong(song.id);
    },
    onSongClick(song: IvorySong) {
      var state = getState();

      state.querySongId = song.id;
      router.push("player");
    },
    onLoginClick() {
      router.push("login");
    },


    async updateTasks() {

      var taskCount = this.tasks == null ? 0 : this.tasks?.length;


      var tasks = await IvoryApi.getRunningTasks();

      this.tasks = tasks!.reverse()!;

      if (this.tasks.length != taskCount) {

        this.updateUploads();
      }

    },

    async onFileUpload(event: any) {
      var refs = <any>this.$refs;
      var file = event.target.files[0];

      const removeExtension = (filename) =>
        filename.split(".").slice(0, -1).join(".") || filename;

      this.homeState.transcriptionFileName = removeExtension(file.name);

      this.transcriptionSource = TranscriptionSource.File;

    },
    handleTranscriptionResult(result: TranscriptionResult) {
      var refs = <any>this.$refs;

      if (result?.status == TranscriptionStatus.Ok) {
        mixpanel.track("Transcribe",);
        this.updateTasks();
      } else if (result?.status == TranscriptionStatus.AlreadyExists) {
        Modals.open(
          "This piece was already transcribed, you can access it using search.", "Information");
      } else if (result?.status == TranscriptionStatus.AlreadyProcessing) {
        Modals.open(
          "This piece is already processing. Please wait until its complete.", "Information");
      } else if (result?.status == TranscriptionStatus.InvalidUrl) {
        Modals.open(
          "The link you provided is not a valid youtube link.", "Information");
      } else if (result.status == TranscriptionStatus.MidiImported) {
        Modals.open("Your file has been added to the library.", "Information");
      } else if (result.status == TranscriptionStatus.NotSubscribed) {
        Modals.open(
          "You need to be subscribed to perform this action.", "Information");
      } else if (result.status == TranscriptionStatus.Error) {
        Modals.open(
          "Unable to process the video. It may be protected by copyright. Please try transcribing another video.", "Information");;
      } else if (result.status == TranscriptionStatus.TooLong) {
        Modals.open(
          "The video is too long. Please try to transcribe a shorter piece.", "Information");;
      } else if (result.status == TranscriptionStatus.Conflict) {
        Modals.open(
          "Please wait your transcription to finish before starting a new one.", "Information");
      }
    },

    async transcribe() {
      var homeState = getHomeState();
      var refs = <any>this.$refs;


      var acceptTos = refs.tosCheck.getValue();

      if (!acceptTos && this.state.account!.role != 5) {
        Modals.open("You need to accept the terms of use before transcribing a piece", "Informations");
        return;
      }

      if (this.transcriptionSource == TranscriptionSource.Youtube) {
        var url = homeState.transcriptionUrl;

        refs.startBtn.toggleLoading(true);
        var result = await IvoryApi.requestProcessingYoutube(
          url,
        );

        this.handleTranscriptionResult(result!);
        refs.startBtn.toggleLoading(false);

        if (result?.status == TranscriptionStatus.Ok) {
          homeState.transcriptionUrl = "";
          homeState.transcriptionFileName = "";

          Snackbars.display("Transcription started.");
        }
      } else if (this.transcriptionSource == TranscriptionSource.File) {
        var file = refs.fileInput.getFile();
        var name = homeState.transcriptionFileName;

        if (name.trim() == "") {

          Modals.open("Please specify a title before transcribing.", "Informations");
          return;
        }

        refs.startBtn.toggleLoading(true);

        let result = await IvoryApi.uploadFile(
          name,
          file,
        );

        this.handleTranscriptionResult(result!);

        refs.startBtn.toggleLoading(false);

        await this.updateTasks();

        await this.updateUploads();

        Snackbars.display("File uploaded.");
      }
    },
    async onYoutubeReady() {

      if (!this.state.loggedIn()) {
        return;
      }
      this.transcriptionSource = TranscriptionSource.Youtube;
    },
  },
  components: { TranscriptionRow, SongRow },
});

import { h, render } from "vue";
import SeSnackbar from "@/components/global/IvorySnackbar.vue";

let snackbarCount = 1;

export function display(
  text: string,
  duration: number = 2500,
  type: "info" | "error" = "info"
) {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbar, { text: text, type: type });

  render(snackbarComponent, snackbarContainer);

  remove(snackbarContainer, duration);

  snackbarCount++;
}

export function remove(snackbarContainer: HTMLDivElement, delay: number) {
  setTimeout(() => {
    snackbarContainer.classList.add("fade-anim");

    setTimeout(() => {
      document.body.removeChild(snackbarContainer);
      snackbarCount--;
    }, 700);
  }, delay);
}

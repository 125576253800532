import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f90f17f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "button",
  type: "button"
}
const _hoisted_2 = { class: "button-body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "text f14 no-mobile",
  style: {"white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon != null && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            width: "25",
            height: "25",
            src: '/ico/' + _ctx.icon + '.svg'
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
            key: 1,
            color: "#c4c4c4",
            style: {"width":"25px","height":"25px"}
          }))
        : _createCommentVNode("", true),
      (_ctx.hasSlot())
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
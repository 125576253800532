import IvorySongData from "./IvorySongData";

export default class IvorySong {
  public id: number = 0;

  public name: string = "";

  public youtubeUrl: string | null = null;

  public youtubeTranscription: boolean | null = null;

  public plays: number = 0;

  public rate: number = 0.0;

  public data: IvorySongData | null = null;

  public isFavorite: boolean = false;

  public pendingRemove: boolean = false;

  public pendingStar: boolean = false;
}

import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvorySheet from "@/model/sheets/IvorySheet";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";
import * as ChordManager from "@/managers/ChordManager";
import { StaveEnum } from "@/model/sheets/StaveEnum";
import * as VexflowUtils from "@/utils/VexflowUtils";
import VF, {
  Accidental,
  Annotation,
  Articulation,
  Beam,
  ClefNote,
  Dot,
  GraceNote,
  Modifier,
  Note,
  NoteSubGroup,
  RenderContext,
  Renderer,
  Stave,
  StaveConnector,
  StaveNote,
  StaveTempo,
  StaveTie,
  StemmableNote,
  Stroke,
  TextBracket,
  Vibrato,
  Voice,
} from "vexflow";
import { nextTick } from "vue";
import IvoryStave from "@/model/sheets/IvoryStave";
import IvoryTimeSignature from "@/model/sheets/IvoryTimeSignature";
import Staff from "./Staff";
import GrandStaff from "./GrandStaff";
import { StaveNoteModifier } from "@/model/sheets/StaveNoteModifier";

export let SHEET_Y_OFFSET = 200;

export let PADDING_X = 400;

export let GAP_BETWEEN_GRAND_STAFF = 280;

export let GAP_BETWEEN_STAFF = 120;

export let CurrentStavePerLine = 0;

export let MAX_STAVE_PER_LINE = 4;

export let KEY_CHANGE_WIDTH_PX = 20;

export default class SheetDrawerSVG {


  container: HTMLDivElement;
  sheet: IvorySheet | null = null;

  context: RenderContext | null;

  allNotes: Map<IvoryStaveNote, Note> = new Map();

  allStaves: Map<Stave, Staff> = new Map();

  x: number = 0;
  y: number = 0;

  grandStaffs: Map<number, GrandStaff> = new Map();

  renderer: Renderer | null = null;

  firstStaveLastClefChange: StaveEnum | null = null;
  secondStaveLastClefChange: StaveEnum | null = null;

  onBarClick: Function | null = null;

  constructor(container: HTMLDivElement) {
    this.container = container;
    this.context = null;
  }

  getTimeSignature(barId: number): IvoryTimeSignature | undefined {
    var ts = this.sheet!.timeSignatures.find((x) => x.barId <= barId);

    return ts;
  }
  buildStaff(
    ivoryStave: IvoryStave,
    bar: IvoryMeasure,
    barX: number,
    barY: number
  ): Staff | null {
    if (bar.staveChange != null) {
      if (ivoryStave.id == 0) {
        this.firstStaveLastClefChange = bar.staveChange;
      } else {
        this.secondStaveLastClefChange = bar.staveChange;
      }
    }



    var clef =
      ivoryStave.id == 0
        ? this.getClef(this.firstStaveLastClefChange!)
        : this.getClef(this.secondStaveLastClefChange!);

    var stave = new VF.Stave(barX, barY, GrandStaff.MIN_STAFF_WIDTH);

    var ts = this.getTimeSignature(bar.number);

    if (ts?.barId == bar.number) {
      stave.addTimeSignature(
        ts.numberOfBeatPerMeasure + "/" + ts.beatNoteValue
      );
    }

    if (this.x == 0) {
      stave.addKeySignature(this.sheet!.key);
      stave.addClef(clef);

      if (this.y == 0 && ivoryStave.id == 0) {
        const tempo = new StaveTempo({ duration: "q", dots: 0, bpm: this.sheet!.getMedianTempo() }, PADDING_X, -20);
        stave.addModifier(tempo);
      }
    } else {
      if (bar.staveChange != null) {
        stave.addClef(this.getClef(bar.staveChange), "small");
      }
    }

    var notes: StemmableNote[] = [];

    for (let ivoryStaveNote of bar.notes) {
      var beamNotes: IvoryStaveNote[] = [];

      if (ivoryStaveNote.beamId != null) {
        beamNotes = bar.notes.filter((x) => x.beamId == ivoryStaveNote.beamId);
      }

      var staveNote: StemmableNote | null = null;

      if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.Grace)) {

        staveNote = new GraceNote({
          clef: clef,
          keys: ivoryStaveNote.keys,
          duration: ivoryStaveNote.duration,
          stemDirection: 1,
          slash: false,
        }).setStave(stave);
      } else {
        staveNote = new StaveNote({
          clef: clef,
          keys: ivoryStaveNote.keys,
          duration: ivoryStaveNote.duration,
          stemDirection: this.getSteamDirection(
            ivoryStaveNote,
            clef,
            beamNotes
          ),
        }).setStave(stave);
      }



      notes.push(staveNote);

      /*  var tempoChange = this.sheet!.tempoChanges.find((x) =>
          ivoryStaveNote.indexes.some((y) => y == x.noteIndex)
        );
  
        if (tempoChange) {
          staveNote.addModifier(
            new VF.Annotation("♪ " + Math.round(tempoChange.tempo * 100) / 100)
              .setFont("Arial", 10, "normal")
              .setVerticalJustification(VF.Annotation.VerticalJustify.TOP)
              .setStyle({ fillStyle: "rgb(160,160,160)" })
          );
        } */

      if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.StrumDown)) {
        staveNote.addStroke(0, new VF.Stroke(4));
      } else if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.StrumUp)) {
        staveNote.addStroke(0, new VF.Stroke(3));
      }
    }

    for (let i = 0; i < bar.notes.length; i++) {
      var n = bar.notes[i];

      this.allNotes.set(n, notes[i]);

      if (n.duration.includes("d")) {
        Dot.buildAndAttach([notes[i]]);
      }

      if (n.keys.length >= 3) {
        var ids = n.keys.map((x) => VexflowUtils.getNoteId(x));
        /*var chord = ChordManager.getChordNameFromIds(ids, false);

        if (chord) {
          notes[i].addModifier(
            new VF.Annotation(chord!)
              .setFont("Arial", 10, "normal")
              .setStyle({ fillStyle: "rgb(120,120,120)" })
          );
        } */
      }
    }

    var ts = this.getTimeSignature(bar.number);
    var voice = new VF.Voice({

      numBeats: ts!.numberOfBeatPerMeasure!,
      beatValue: ts!.beatNoteValue!,
      resolution: VF.RESOLUTION
    })
      .setStave(stave)
      .setStrict(false)
      .addTickables(notes)
      .setMode(Voice.Mode.SOFT);


    // VF.Accidental.applyAccidentals([voice], this.sheet!.key);

    stave.setContext(this.context!);

    voice.setContext(this.context!);


    let vexMeasure = new Staff(
      ivoryStave.id,
      bar,
      stave,
      [],
      [],
      voice,
      barX,
      barY
    );



    return vexMeasure;
  }

  getSteamDirection(
    note: IvoryStaveNote,
    clef: string,
    beamNotes: IvoryStaveNote[]
  ) {
    let keyRef = clef == "treble" ? 50 : 29;

    if (note.beamId != null) {
      if (beamNotes.some((x) => VexflowUtils.getNoteId(x.keys[0]) > keyRef)) {
        return -1;
      } else {
        return 1;
      }
    }

    var num = VexflowUtils.getNoteId(note.keys[0]);
    if (num > keyRef) {
      return -1;
    }
    return 1;
  }

  getClef(staveEnum: StaveEnum) {
    switch (staveEnum) {
      case StaveEnum.Bass:
        return "bass";
      case StaveEnum.Treble:
        return "treble";
    }
  }
  initialize() {
    // 1. Convert A4 width (210 mm) to pixels (assuming 96 DPI)
    const A4_WIDTH_MM = 300; // 210 IS A4 but lets do a little more
    const DPI = 96; // typical value for web
    const A4_WIDTH_PX = (A4_WIDTH_MM / 25.4) * DPI; // ≈ 794 pixels for 210 (A4)

    // 2. Calculate horizontal padding so that the drawing area is exactly A4 width.
    PADDING_X = (window.innerWidth - A4_WIDTH_PX) / 2;

    // Optional: if the window is narrower than A4, ensure padding is not negative.
    if (PADDING_X < 0) {
      PADDING_X = 0;
    }

    // Now continue setting up the renderer and context.
    this.renderer = new VF.Renderer(this.container, VF.Renderer.Backends.SVG);
    this.context = this.renderer.getContext();

    // The overall renderer still uses window.innerWidth (or container width)

  }
  drawTitle() {
    this.context!.setFont("Garamond", 30, "normal").setBackgroundFillStyle(
      "black"
    );

    let titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(this.sheet!.title).width / 2;

    let titleY = 100;

    this.context!.fillText(this.sheet!.title, titleX, titleY);

    this.context!.setFont("Garamond", 12, "normal").setFillStyle("gray");

    let text = "Key : " + this.sheet!.key;



    titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(text).width / 2;

    titleY = 125;

    this.context!.fillText(text, titleX, titleY);

    this.context!.setFont("Arial", 12, "normal").setFillStyle("gray");

    var infoMessage =
      "This piano score is automatically generated and still a work in progress. We are actively improving it, and appreciate your understanding as we refine it.";

    titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(infoMessage).width / 2;

    titleY = 125;

    this.context?.setFillStyle("black");
  }
  updateStavePerLine(n: number) {
    CurrentStavePerLine = n;
    GrandStaff.MIN_STAFF_WIDTH =
      this.container.offsetWidth / CurrentStavePerLine -
      PADDING_X / (CurrentStavePerLine / 2);
  }
  computeNumberOfStaveForLine(
    barId: number,
    firstStave: IvoryStave,
    secondStave: IvoryStave
  ): number {
    // Try candidate counts from MAX_STAVE_PER_LINE (4) down to 2.
    for (let candidate = MAX_STAVE_PER_LINE; candidate >= 2; candidate--) {
      // Get only the measures for the current candidate window.
      const measures1 = firstStave.bars.slice(barId, barId + candidate);
      const measures2 = secondStave.bars.slice(barId, barId + candidate);

      // Ensure we have enough measures in both staves.
      if (measures1.length < candidate || measures2.length < candidate) {
        continue;
      }

      // Compute the maximum note count among the candidate measures.
      let maxNotes = 0;
      for (let i = 0; i < candidate; i++) {
        maxNotes = Math.max(maxNotes, measures1[i].notes.length, measures2[i].notes.length);
      }

      // Set thresholds for each candidate value.
      let threshold: number;
      switch (candidate) {
        case 4:
          threshold = 6;
          break;
        case 3:
          threshold = 10;
          break;
        case 2:
          threshold = 18;
          break;
        default:
          threshold = Number.MAX_SAFE_INTEGER;
      }

      // If the maximum note count is within the threshold, choose this candidate.
      if (maxNotes <= threshold) {
        return candidate;
      }
    }

    // If none of the candidate groups qualifies, default to one stave per line.
    return 1;
  }




  build(sheet: IvorySheet) {
    this.sheet = sheet;

    var maxLength = 0;

    this.sheet?.firstStave.bars.forEach((x) => {
      maxLength = Math.max(maxLength, x.notes.length);
    });
    this.sheet?.secondStave.bars.forEach((x) => {
      maxLength = Math.max(maxLength, x.notes.length);
    });

    this.updateStavePerLine(CurrentStavePerLine);

    this.x = 0;
    this.y = 0;

    this.firstStaveLastClefChange = this.sheet.firstStave.staveEnum;
    this.secondStaveLastClefChange = this.sheet.secondStave.staveEnum;

    this.grandStaffs.clear();
    this.allNotes.clear();
    this.allStaves.clear();

    let barCount = Math.min(
      this.sheet?.firstStave.bars.length!,
      this.sheet?.secondStave.bars.length!
    );

    let xPx = PADDING_X;

    let yPx = SHEET_Y_OFFSET;

    for (let barId = 0; barId < barCount; barId++) {





      let ivoryMeasure1 = this.sheet?.firstStave.bars[barId]!;

      let ivoryMeasure2 = this.sheet?.secondStave.bars[barId]!;


      if (this.x == 0) {
        this.updateStavePerLine(this.computeNumberOfStaveForLine(barId, this.sheet!.firstStave, this.sheet.secondStave!));
      }

      var firstMeasure = this.buildStaff(
        this.sheet?.firstStave,
        ivoryMeasure1,
        xPx,
        yPx
      );

      var secondMeasure = this.buildStaff(
        this.sheet?.secondStave,
        ivoryMeasure2,
        xPx,
        yPx + GAP_BETWEEN_STAFF
      );



      var group = new GrandStaff(
        barId,
        this.x,
        this.y,
        firstMeasure!,
        secondMeasure!,
        (barId, noteIndex) => this.onBarClick!(barId, noteIndex)
      );
      group.build(this.context!);

      this.buildBeams(group.measure1);

      this.buildBeams(group.measure2);

      group.format();

      this.grandStaffs.set(barId, group);

      this.allStaves.set(firstMeasure!.stave, firstMeasure!);
      this.allStaves.set(secondMeasure!.stave, secondMeasure!);

      this.x++;

      xPx += group.width!;

      if (this.x >= CurrentStavePerLine) {
        this.x = 0;
        this.y++;
        xPx = PADDING_X;
        yPx += GAP_BETWEEN_GRAND_STAFF;
      }
    }

    if (this.grandStaffs.size > 0) {
      let lastStaff: GrandStaff = this.grandStaffs.get(this.grandStaffs.size - 1)!;
      var cnn = new StaveConnector(lastStaff.measure1.stave, lastStaff.measure2.stave);
      cnn.setType(6);
      cnn.setContext(this.context!);
      lastStaff.connectors.push(cnn);

    }


    this.buildTies();

    var totalHeight =
      (this.y + 1) * GAP_BETWEEN_GRAND_STAFF +
      SHEET_Y_OFFSET +
      GAP_BETWEEN_STAFF;

    this.renderer!.resize(this.container.offsetWidth, totalHeight);
  }

  draw() {


    this.context?.clear();

    for (let group of this.grandStaffs.values()) {
      group.draw(this.context!);
    }

    nextTick(() => {
      this.drawTitle();

    });





  }

  bindEvents() {


    for (let group of this.grandStaffs.values()) {
      group.bindEvents();
    }

    this.vexBugfix();
  }

  vexBugfix() {

    const svg = (<any>this.renderer?.getContext()).svg;

    svg.setAttribute("pointer-events", "all");

    const rects = svg.querySelectorAll('rect');
    rects.forEach(rect => {
      if (window.getComputedStyle(rect).opacity === "0") {
        rect.remove();
      }
    });
  }

  buildBeams(staff: Staff) {

    function groupNotesByBeamId(notes: IvoryStaveNote[]): Map<number, IvoryStaveNote[]> {
      return notes.reduce((groups, note) => {
        if (note.beamId !== null) {
          if (!groups.has(note.beamId)) {
            groups.set(note.beamId, []);
          }
          groups.get(note.beamId)!.push(note);
        }
        return groups;
      }, new Map<number, IvoryStaveNote[]>());
    }


    let groups = groupNotesByBeamId(staff.ivoryMesure.notes);

    for (let group of groups.values()) {

      let staveNotes = group.map(x => <StemmableNote>this.allNotes.get(x))!;

      if (staveNotes.length > 1) {
        let beam = new Beam(staveNotes, true);
        beam.setContext(this.context!);

        staff.beams.push(beam);
      }


    }

  }


  buildTies() {
    const groupedByTies = Array.from(this.allNotes.keys()).reduce<
      Map<number, IvoryStaveNote[]>
    >(
      (res, note) => {
        const tieId = note.tieId;

        if (tieId == null) {
          return res;
        }

        if (!res.has(tieId)) {
          res.set(tieId, []);
        }

        res.get(tieId)!.push(note);

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    for (let group of groupedByTies) {
      var notes = group[1];

      for (let i = 0; i < notes.length - 1; i++) {
        var n1 = this.allNotes.get(notes[i]);

        var n2 = this.allNotes.get(notes[i + 1]);

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie = new StaveTie({ firstNote: n1, lastNote: null });
        } else {
          var tie = new StaveTie({ firstNote: n1, lastNote: n2 });
        }

        tie.setContext(this.context!);

        this.allStaves.get(n1?.getStave()!)?.ties.push(tie);
        this.allStaves.get(n1?.getStave()!)?.ties.push(tie);

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie2 = new StaveTie({ firstNote: null, lastNote: n2 });
          tie2.setContext(this.context!);

          this.allStaves.get(n2?.getStave()!)?.ties.push(tie2);
        }
      }
    }
  }
}

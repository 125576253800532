import IvoryMeasure from "./IvoryMeasure";
import { StaveEnum } from "./StaveEnum";

export default class IvoryStave {


  public id: number = -1;
  public bars: IvoryMeasure[] = [];
  public staveEnum: StaveEnum = StaveEnum.Treble;

  constructor(id: number, bars: IvoryMeasure[], staveEnum: StaveEnum) {
    this.id = id;
    this.bars = bars;
    this.staveEnum = staveEnum;
  }
  static fromJson(x: any): IvoryStave {
    return new IvoryStave(x.id, x.bars.map(x => IvoryMeasure.fromJson(x)), x.staveEnum);
  }
}

import { StaveNoteModifier } from "./StaveNoteModifier";

export default class IvoryStaveNote {

  public keys: string[] = [];

  public duration: string = "";

  public beamId: number | null = null;

  public tieId: number | null = null;

  public indexes: number[] = [];

  public modifiers: StaveNoteModifier[] = [];

  public isRest() {
    return this.duration.includes("r");
  }

  constructor(keys: string[], duration: string, beamId: number | null, tieId: number | null, indexes: number[], modifiers: StaveNoteModifier[]) {
    this.keys = keys;
    this.duration = duration;
    this.beamId = beamId;
    this.tieId = tieId;
    this.indexes = indexes;
    this.modifiers = modifiers;
  }
  static fromJson(x: any) {
    return new IvoryStaveNote(x.keys, x.duration, x.beamId, x.tieId, x.indexes, x.modifiers);
  }
}


import { defineComponent, nextTick } from "vue";
import { getState } from "@/state/IvoryState";
import { useRoute, useRouter } from "vue-router";
import * as IvoryApi from "@/IvoryApi";
import SheetDrawerSVG from "@/managers/SheetDrawerSVG";
import SheetHeader from "@/components/sheet/SheetHeader.vue";
import IvoryInput from "@/components/global/IvoryInput.vue";
import { getSheetState } from "@/state/SheetState";
import IvoryBar from "@/components/global/IvoryBar.vue";
import Keyboard from "@/components/player/keys/Keyboard.vue";

export default defineComponent({
  data() {
    return {};
  },

  setup() {
    var router = useRouter();
    var appState = getState();
    var state = getSheetState();

    return {
      state: state,
      appState: appState,
      router: router,
    };
  },
  beforeUnmount() {
    document.removeEventListener("visibilitychange", this.state.stop);
    this.state.stop();
  },

  components: { SheetHeader, Keyboard },
  computed: {},
  async mounted() {
    this.state.view = this;

    const params = new URLSearchParams(window.location.search);
    const tempo = params.get("tempo");

    var container: HTMLDivElement = <HTMLDivElement>(
      document.getElementById("svgContainer")
    );

    await this.state.initialize(this.appState.querySongId!, tempo!, container);

    document.addEventListener("visibilitychange", this.state.stop);

    this.appState.querySongId = null;
  },
  methods: {
    openEditorBar() {
      var refs = <any>this.$refs;
      refs.editorBar.open();
    },
    getKeyboard() {
      var refs = <any>this.$refs;
      return refs.keyboard;
    },
  },
});

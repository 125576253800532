import IvoryNote from "./IvoryNote";
import * as ChordManager from "@/managers/ChordManager";

export default class IvoryChord {
  updateName() {
    this.name = ChordManager.getChordNameFromIds(
      this.notes.map((x) => x.number + 1)!,
      true
    );
  }
  public start: number = 0;

  public end: number = 0;

  public name: string | null = "";

  public notes: IvoryNote[] = [];
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3209b0e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-row" }
const _hoisted_2 = { class: "star-container" }
const _hoisted_3 = ["onClick", "src"]
const _hoisted_4 = { class: "f10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
        return _createElementVNode("img", {
          onClick: ($event: any) => (_ctx.starClick(n)),
          style: _normalizeStyle(_ctx.starStyle),
          class: "star",
          src: _ctx.getIconName(n)
        }, null, 12, _hoisted_3)
      }), 64))
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.rateCurrent), 1)
  ]))
}

import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
    },
    defaultValue: {
      type: Boolean,
      default: undefined,
      required: false,
    },
    variant: {
      type: String,
      default: "white",
      validator: (value: string) => ["white", "transparent"].includes(value),
    },
    toggleIconText: {
      type: String,
      default: "",
    },
    click: {
      type: Function,
      default: null,
    },
    valueChanged: {
      type: Function,
      default: null,
    },
    valueFunc: {
      type: Function,
      default: null,
    },
  },
  expose: ["toggle", "toggled"],
  emits: ["update:modelValue"],

  mounted() {
    if (this.defaultValue != undefined) {
      this.toggle(this.defaultValue, false);
    } else {
      this.toggle(this.modelValue, false);
    }
  },
  methods: {
    onClick() {
      this.toggle(!this.toggled(), true);

      if (this.click != null) {
        this.click(this.toggled());
      }
      if (this.valueChanged != null) {
        this.valueChanged(this.toggled());
      }
    },
    toggled() {
      return this.$el.querySelector("input").checked;
    },
    toggle(active: boolean, emit: boolean) {
      let input = this.$el.querySelector("input");
      input.checked = active;

      if (emit) {
        this.$emit("update:modelValue", active);
      }
    },
  },
  watch: {
    modelValue(value: boolean, old: boolean) {
      if (value != old) {
        this.toggle(value, false);
      }
    },
  },
  computed: {
    variantClass() {
      return this.variant === "transparent" ? "transparent-variant" : "";
    },
  },
});

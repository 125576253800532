let VexlowNoteMap = [
  "a/0",
  "a#/0",
  "b/0",
  "c/1",
  "c#/1",
  "d/1",
  "d#/1",
  "e/1",
  "f/1",
  "f#/1",
  "g/1",
  "g#/1",
  "a/1",
  "a#/1",
  "b/1",
  "c/2",
  "c#/2",
  "d/2",
  "d#/2",
  "e/2",
  "f/2",
  "f#/2",
  "g/2",
  "g#/2",
  "a/2",
  "a#/2",
  "b/2",
  "c/3",
  "c#/3",
  "d/3",
  "d#/3",
  "e/3",
  "f/3",
  "f#/3",
  "g/3",
  "g#/3",
  "a/3",
  "a#/3",
  "b/3",
  "c/4",
  "c#/4",
  "d/4",
  "d#/4",
  "e/4",
  "f/4",
  "f#/4",
  "g/4",
  "g#/4",
  "a/4",
  "a#/4",
  "b/4",
  "c/5",
  "c#/5",
  "d/5",
  "d#/5",
  "e/5",
  "f/5",
  "f#/5",
  "g/5",
  "g#/5",
  "a/5",
  "a#/5",
  "b/5",
  "c/6",
  "c#/6",
  "d/6",
  "d#/6",
  "e/6",
  "f/6",
  "f#/6",
  "g/6",
  "g#/6",
  "a/6",
  "a#/6",
  "b/6",
  "c/7",
  "c#/7",
  "d/7",
  "d#/7",
  "e/7",
  "f/7",
  "f#/7",
  "g/7",
  "g#/7",
  "a/7",
  "a#/7",
  "b/7",
  "c/8",
];

export function getNoteId(vexflowNote: string) {
  return VexlowNoteMap.indexOf(vexflowNote) + 1;
}

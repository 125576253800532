export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
export function rgbToHex(r: number, g: number, b: number) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

export function randomColorRgb(minimumDelta: number = 0) {
  let r = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;
  let g = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;
  let b = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;

  return {
    r: r,
    g: g,
    b: b,
  };
}
export function darkenColor(hex: string, percent: number) {
  // Validate the input
  if (typeof hex !== "string" || !/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid hex color format. Use #RRGGBB");
  }

  if (typeof percent !== "number" || percent < 0 || percent > 100) {
    throw new Error("Percentage should be a number between 0 and 100.");
  }

  // Remove the '#' from the beginning of the hex string
  hex = hex.slice(1);

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darkened RGB values
  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  // Convert back to hex
  let rS = r.toString(16).padStart(2, "0");
  let gS = g.toString(16).padStart(2, "0");
  let bS = b.toString(16).padStart(2, "0");

  // Return the darkened color as hex
  return `#${rS}${gS}${bS}`;
}

export function brightenColor(hex: string, percent: number) {
  // Validate the input
  if (typeof hex !== "string" || !/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid hex color format. Use #RRGGBB");
  }

  if (typeof percent !== "number" || percent < 0 || percent > 100) {
    throw new Error("Percentage should be a number between 0 and 100.");
  }

  // Remove the '#' from the beginning of the hex string
  hex = hex.slice(1);

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the brightened RGB values
  r = Math.min(Math.round(r + r * (percent / 100)), 255);
  g = Math.min(Math.round(g + g * (percent / 100)), 255);
  b = Math.min(Math.round(b + b * (percent / 100)), 255);

  // Convert back to hex
  let rS = r.toString(16).padStart(2, "0");
  let gS = g.toString(16).padStart(2, "0");
  let bS = b.toString(16).padStart(2, "0");

  // Return the brightened color as hex
  return `#${rS}${gS}${bS}`;
}

export function changeAlpha(rgba, alpha) {
  // Validate alpha value
  if (alpha < 0 || alpha > 1) {
    throw new Error("Alpha value must be between 0 and 1.");
  }

  // Regular expression to match the rgba color components
  const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(0?\.\d+|1|0)\)$/;

  // Match the input RGBA string
  const match = rgba.match(rgbaRegex);
  if (!match) {
    throw new Error("Invalid RGBA color string.");
  }

  // Extract the red, green, and blue components
  const red = match[1];
  const green = match[2];
  const blue = match[3];

  // Construct the new RGBA color string with the updated alpha
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function getInvertedColor(hex: string): string {
  const { r, g, b } = hexToRgb(hex)!;
  // Invert each channel
  const invertedR = 255 - r;
  const invertedG = 255 - g;
  const invertedB = 255 - b;

  return rgbToHex(invertedR, invertedG, invertedB);
}

// Convert RGB to HSL
function rgbToHsl(
  r: number,
  g: number,
  b: number
): { h: number; s: number; l: number } {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number, s: number;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0; // should never happen
    }
    h *= 60;
  }

  return { h, s, l };
}

// Convert HSL back to RGB
function hslToRgb(
  h: number,
  s: number,
  l: number
): { r: number; g: number; b: number } {
  const C = (1 - Math.abs(2 * l - 1)) * s;
  const X = C * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - C / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = C;
    g = X;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = X;
    g = C;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = C;
    b = X;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = X;
    b = C;
  } else if (240 <= h && h < 300) {
    r = X;
    g = 0;
    b = C;
  } else if (300 <= h && h < 360) {
    r = C;
    g = 0;
    b = X;
  }

  return {
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
  };
}

// WCAG Luminance
function getLuminance(r: number, g: number, b: number): number {
  const [R, G, B] = [r, g, b].map((v) => {
    const s = v / 255;
    return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

// Contrast ratio
function getContrastRatio(l1: number, l2: number): number {
  const lighter = Math.max(l1, l2);
  const darker = Math.min(l1, l2);
  return (lighter + 0.05) / (darker + 0.05);
}

/**
 * Get a contrasting, readable color by:
 * 1. Computing the complementary hue (h + 180)
 * 2. Trying two extremes of lightness (very dark and very light)
 * 3. Choosing the one with better contrast ratio
 */
export function getReadableComplement(hex: string): string {
  const rgb = hexToRgb(hex);
  if (!rgb) return "#000000"; // fallback

  const { h, s, l } = rgbToHsl(rgb.r, rgb.g, rgb.b);

  // Complementary hue
  let h2 = (h + 180) % 360;
  let s2 = s; // keep saturation (or adjust as desired)

  // Try dark (l2 = 0.1) and light (l2 = 0.9)
  // You can adjust these extremes as needed
  const lightCandidates = [0.1, 0.9];
  const bgLuminance = getLuminance(rgb.r, rgb.g, rgb.b);

  let bestColor = "#000000";
  let bestContrast = 0;

  for (let candidateL of lightCandidates) {
    const { r, g, b } = hslToRgb(h2, s2, candidateL);
    const fgLuminance = getLuminance(r, g, b);
    const contrast = getContrastRatio(bgLuminance, fgLuminance);
    if (contrast > bestContrast) {
      bestContrast = contrast;
      bestColor = rgbToHex(r, g, b);
    }
  }

  return bestColor;
}


import { defineComponent } from "vue";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  expose: ["toggleLoading", "isLoading", "getFile", "reset"],
  data() {
    return {
      loading: false,
      fileName: null as string | null,
      file: null as File | null,
      dragActive: false
    };
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: "upload",
    },
    accept: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    getFile() {
      return this.file;
    },
    onChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        this.file = input.files[0];
        this.fileName = DOMUtils.limitStringSize(this.file.name, 60);
      }
    },
    dragEnter(e: DragEvent) {
      this.dragActive = true;
    },
    dragLeave(e: DragEvent) {
      this.dragActive = false;
    },
    onDrop(e: DragEvent) {
      e.preventDefault();
      this.dragActive = false;
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
        this.file = e.dataTransfer.files[0];
        this.fileName = DOMUtils.limitStringSize(this.file.name, 60);
      }
    },
    isLoading() {
      return this.loading;
    },
    toggleLoading(loading: boolean) {
      this.loading = loading;
    },
    reset() {
      this.file = null;
      this.fileName = null;
      const input = this.$refs.fileInput as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },
  },
});

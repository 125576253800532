import IvorySong from "../songs/IvorySong";
import IvorySongData from "../songs/IvorySongData";
import IvorySheet from "./IvorySheet";

export default class SheetGenerationResult {
  public sheet: IvorySheet | null = null;

  public data: IvorySongData | null = null;

  static fromJson(x: any) {
    return new SheetGenerationResult(IvorySheet.fromJson(x.sheet), x.data);
  }

  constructor(sheet: IvorySheet, data: IvorySongData) {
    this.sheet = sheet;
    this.data = data;
  }
}

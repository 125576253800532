
import { defineComponent } from "vue";
import * as ColorUtils from "@/utils/ColorUtils";

const __default__ = defineComponent({
  emits: ["update:modelValue"],

  data() {
    return {
      ColorUtils: ColorUtils,
    };
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.getValue());

      if (this.change != undefined) {
        this.change(this.getValue());
      }
    },
    getValue() {
      return parseFloat(this.$el.childNodes[0].value);
    },
  },
  props: ["modelValue", "color", "max", "min", "change"],
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2fd42d7a": (_ctx.color),
  "778a5c0c": (_ctx.ColorUtils.darkenColor(_ctx.color, 40))
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__

import IvorySong from "@/model/songs/IvorySong";
import { defineComponent } from "vue";
import IvoryModal from "./IvoryModal.vue";
import IvoryButton from "./IvoryButton.vue";
import * as IvoryApi from "@/IvoryApi";
import IvoryCheckbox from "./IvoryCheckbox.vue";
import IvoryDropdown from "./IvoryDropdown.vue";
import { DownloadType } from "../player/DownloadType";
import IvoryDropdownItem from "./IvoryDropdownItem.vue";
import * as Youtube from "@/utils/Youtube";
import IvoryIcon from "./IvoryIcon.vue";
import mixpanel from "mixpanel-browser";
import { getState } from "@/state/IvoryState";

export default defineComponent({
  components: {
    IvoryModal,
    IvoryCheckbox,
    IvoryDropdown,
    IvoryDropdownItem,
    IvoryButton,
    IvoryIcon,
  },
  props: {
    song: {
      type: IvorySong,
      required: true,
    },
  },
  expose: ["open"],

  data() {
    return {
      midiPedalDurations: true,
      downloadType: DownloadType.MIDI,
      DownloadType: DownloadType,
      Youtube: Youtube,
      role: getState().account?.role,
    };
  },
  methods: {
    download() {
      var refs = <any>this.$refs;

      mixpanel.track("download", { type: this.downloadType.toString() });

      if (this.downloadType == DownloadType.MIDI) {
        let url = IvoryApi.getMidiDownloadLink(
          this.song.id,
          this.midiPedalDurations
        );
        window.open(url);
      } else if (this.downloadType == DownloadType.Json) {
        let url = IvoryApi.getJsonDownloadLink(this.song.id);
        window.open(url);
      }

      refs.mod.close();
    },
    open() {
      var refs = <any>this.$refs;
      refs.mod.open();
    },
  },
});

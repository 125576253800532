export let BlackKeyCount: number = 36;

export let WhiteKeyCount: number = 52;

export let KeyCount: number = BlackKeyCount + WhiteKeyCount;

export let DiscordLink = "https://discord.gg/qjQ3WeJBkk";

export let InstagramLink = "https://www.instagram.com/ivory.app/";

export let TiktokLink = "https://www.tiktok.com/@ivory.app";

export let DefaultBackground =
  "linear-gradient(135deg, hsla(265, 53%, 12%, 0.9) 0%, hsla(231, 30%, 38%, 0.6) 50%, hsla(24, 93%, 73%, 0.7) 100%)";

export let Version = "2.1.0";

import IvorySong from "@/model/songs/IvorySongData";

export interface NoteIntervals {
  [key: string]: number;
}

export const NoteIntervals: NoteIntervals = {
  C: 0,
  "C#": 1,
  D: 2,
  "D#": 3,
  E: 4,
  F: 5,
  "F#": 6,
  G: 7,
  "G#": 8,
  A: 9,
  "A#": 10,
  B: 11,
};

export const ChordTypes = [
  { name: "", intervals: [4, 7] }, // major
  { name: "maj7", intervals: [4, 7, 11] },
  { name: "maj7", intervals: [4, 11] },
  { name: "m", intervals: [3, 7] },
  { name: "m7", intervals: [3, 7, 10] },
  { name: "aug", intervals: [4, 8] },
  { name: "dim", intervals: [3, 6] },
  { name: "dim7", intervals: [3, 6, 9] },
  { name: "m7b5", intervals: [3, 6, 10] },
  { name: "7", intervals: [4, 7, 10] },
  { name: "9", intervals: [4, 7, 10, 2] },
  { name: "m9", intervals: [3, 7, 10, 2] },

  { name: "6", intervals: [4, 7, 9] },
  { name: "sus2", intervals: [2, 7] },
  { name: "sus4", intervals: [5, 7] },
  { name: "6/9", intervals: [2, 4, 7, 9] },
  { name: "add9", intervals: [4, 7, 2] },

  { name: "madd9", intervals: [3, 7, 2] },

  { name: "7b5", intervals: [4, 6, 10] },
  { name: "b5", intervals: [4, 6] },

  { name: "m(maj7)", intervals: [3, 7, 11] },
  { name: "m6", intervals: [3, 7, 9] },
  { name: "maj7sus4", intervals: [5, 7, 11] },
  { name: "m7sus4", intervals: [5, 7, 10] },

  { name: "13", intervals: [4, 7, 10, 2, 9] }, // Dominant 13th
  { name: "maj13", intervals: [4, 7, 11, 2, 9] }, // Major 13th
  { name: "m13", intervals: [3, 7, 10, 2, 9] }, // Minor 13th
];

function getInterval(notes: string[], root: string): number[] {
  const sortedNotes = notes; //notes.slice().sort((a, b) => noteIntervals[a] - noteIntervals[b]);

  var results: number[] = [];

  for (let i = 1; i < notes.length; i++) {
    results.push(
      (NoteIntervals[sortedNotes[i]] - NoteIntervals[root] + 12) % 12
    );
  }

  return results;
}

function filterCoherence(arr: number[]): number[] {
  const result = [arr[0]]; // Initialize result array with the first element

  for (let i = 2; i < arr.length; i++) {
    if (Math.abs(arr[i] - result[result.length - 1]) <= 12) {
      result.push(arr[i]);
    }
  }

  return result;
}
export function chordNameToNotes(chordName: string): number[] | null {
  // Regular expression to extract the root note and chord type
  const match = chordName.match(/^([A-G][#b]?)(.*)$/);

  if (!match) {
    console.error(`Invalid chord name: ${chordName}`);
    return null;
  }

  const rootNote = match[1];
  const chordType = match[2];

  // Find the chord type object based on the chordType
  const chord = ChordTypes.find((c) => c.name === chordType);

  if (!chord) {
    console.error(`Chord type ${chordType} not found.`);
    return null;
  }

  // Get the intervals for the chord type
  const intervals = chord.intervals;

  // Get the root note ID
  const rootNoteId = NoteIntervals[rootNote];

  if (rootNoteId === undefined) {
    console.error(`Root note ${rootNote} is invalid.`);
    return null;
  }

  // Calculate the note IDs based on the intervals and the root note
  const noteIds = intervals.map((interval) => (rootNoteId + interval) % 12);

  return noteIds;
}

export function getChordNameFromIds(
  notes: number[],
  bassLookup: boolean = true
) {
  var noteNames = notes.map((x) => getNoteName(x));
  return getChordName(noteNames, bassLookup);
}
export function getChordName(
  noteNames: string[],
  bassLookup: boolean = true
): string | null {
  noteNames = removeDuplicates(noteNames);

  for (var permutation of generatePermutations(noteNames)) {
    var result = identifyWithRoot(permutation);

    if (result != null) {
      return result;
    }
  }

  if (bassLookup) {
    var bass = noteNames[0];

    noteNames.shift()!;

    var result = getChordName(noteNames, false);

    if (result != null) {
      return bass + "/" + result;
    }
  }

  return null;
}

function identifyWithRoot(permutation: string[]) {
  var root = permutation[0];

  var interval = getInterval(permutation, root);

  let modifier = ChordTypes.find((chord) =>
    arraysHaveSameValues(interval, chord.intervals)
  );

  if (modifier != null) {
    return root + modifier?.name;
  }
  return null;
}

function generatePermutations(arr: string[]): string[][] {
  if (arr.length <= 1) {
    return [arr];
  }

  const result: string[][] = [];

  for (let i = 0; i < arr.length; i++) {
    const [current] = arr.splice(i, 1);
    const permutations = generatePermutations(arr.slice());
    for (const permutation of permutations) {
      result.push([current, ...permutation]);
    }
    arr.splice(i, 0, current);
  }

  return result;
}

function arraysHaveSameValues(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

function removeDuplicates(arr: string[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function getNoteName(index: number): string {
  const noteNames = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
  ];
  return noteNames[(index - 1) % 12];
}

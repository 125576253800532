import IvoryStave from "./IvoryStave";
import Stave from "./IvoryStave";
import IvoryStaveNote from "./IvoryStaveNote";
import IvoryTextBracket from "./IvoryTextBracket";
import { StaveEnum } from "./StaveEnum";

export default class IvoryMeasure {

  public notes: IvoryStaveNote[] = [];

  public number: number = 0;

  public staveChange: StaveEnum | null = null;



  constructor(notes: IvoryStaveNote[], number: number, staveChange: StaveEnum | null) {
    this.notes = notes;
    this.number = number;
    this.staveChange = staveChange;
  }

  static fromJson(x: any) {
    return new IvoryMeasure(x.notes.map(x => IvoryStaveNote.fromJson(x)), x.number, x.staveChange);
  }
}

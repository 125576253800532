
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { useRouter } from "vue-router";
import IvoryLayout from "@/components/IvoryLayout.vue";
import { PlayerState } from "@/state/PlayerState";
import mixpanel from "mixpanel-browser";

export default defineComponent({
  components: { IvoryLayout },

  setup() {
    if (getState().isMobileVersion()) {
      PlayerState.NoteHeightSecond = 150;
    }
  },

  mounted() {
    this.timeout30s = setTimeout(() => {
      mixpanel.track("30 seconds on player");
    }, 1000 * 30);

    this.timeout120s = setTimeout(() => {
      mixpanel.track("2 minutes on player");
    }, 1000 * 120);
  },
  unmounted() {
    if (this.timeout30s != null) {
      clearTimeout(this.timeout30s);
    }
    if (this.timeout120s != null) {
      clearTimeout(this.timeout120s);
    }
  },
  data() {
    return {
      timeout30s: null as number | null,
      timeout120s: null as number | null,
    };
  },
});

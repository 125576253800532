
import IvorySong from "@/model/songs/IvorySong";
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";
import * as Youtube from "@/utils/Youtube";
import * as Snackbars from "@/utils/Snackbars";

export default defineComponent({
  props: {
    song: {
      required: true,
      type: IvorySong,
    },
    tailing: {
      type: Boolean,
      required: false,
      default: false,
    },
    onDeleted: {
      type: Function,
      required: false,
      default: null,
    }
  },

  components: { HalfCircleSpinner },

  data() {
    return {
      state: getState(),
      DOMUtils: DOMUtils,
      Youtube: Youtube,
    };
  },
  methods: {
    downloadClick(song: IvorySong) {
      getState().download(song);
    },
    async removeSong(song: IvorySong) {
      song.pendingRemove = true;

      await IvoryApi.removeSong(song.id);

      if (this.onDeleted) {
        this.onDeleted(song);
      }
      //    this.$el.style.display = "none";
    },
    shareClick() {
      navigator.clipboard
        .writeText(window.location.host + "/player?id=" + this.song.id)
        .then(() => {
          Snackbars.display("The link was copied to clipboard.");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    },
    getFavIcon() {
      let state = getState();

      if (this.song.isFavorite) {
        return "heart-filled";
      } else {
        return "heart";
      }
    },
    async favoriteClick() {
      this.song.pendingStar = true;

      var refs = <any>this.$refs;

      await IvoryApi.toggleFavorite(this.song.id!);

      this.song.isFavorite = !this.song.isFavorite;

      if (!this.song.isFavorite) {
        refs.favIcon.source = "/ico/heart.svg";
      } else {

        refs.favIcon.source = "/ico/heart-filled.svg";
      }

      this.song.pendingStar = false;
    },
    sheetClick() {
      let state = getState();
      state.openSheet(this.song);
    },

    async rateSong(n: number) {
      await IvoryApi.rateSong(this.song!.id!, n);

      this.song.rate = n;

    },
  },
});

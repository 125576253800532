
import IvorySong from "@/model/songs/IvorySong";
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";
import * as Youtube from "@/utils/Youtube";
import NewsRecord from "@/model/accounts/NewsRecord";
import * as TimeUtils from "@/utils/TimeUtils";

export default defineComponent({
  props: {
    news: NewsRecord,
  },

  components: { HalfCircleSpinner },

  data() {
    return {
      state: getState(),
      DOMUtils: DOMUtils,
      rated: false,
      TimeUtils: TimeUtils,
    };
  },
  methods: {
    async rateNew() {
      if (this.rated) {
        return;
      }
      this.news!.likes++;
      await IvoryApi.rateNews(this.news!.id);
      this.rated = true;
    },
  },
});


import { defineComponent, nextTick } from "vue";
import * as TimeUtils from "@/utils/TimeUtils";
import IvoryButton from "./global/IvoryButton.vue";
import { Midi } from "@tonejs/midi";
import { getState } from "@/state/IvoryState";
import * as ColorUtils from "@/utils/ColorUtils";
import * as Constants from "@/Constants";
import { useRoute, useRouter } from "vue-router";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";
import * as IvoryApi from "@/IvoryApi";
import { HalfCircleSpinner } from "epic-spinners";
import * as Snackbars from "@/utils/Snackbars";
import { getSheetState } from "@/state/SheetState";
import IvoryIcon from "../global/IvoryIcon.vue";
import { play } from "@/managers/SoundManager";

export default defineComponent({
  data() {
    var appState = getState();
    var state = getSheetState();
    return {
      TimeUtils: TimeUtils,
      appState: appState,
      state: state,
      ColorUtils: ColorUtils,
      currentTime: 0,
      DOMUtils: DOMUtils,
    };
  },

  mounted() {},
  unmounted() {},
  setup() {
    var router = useRouter();
    return {
      router: router,
    };
  },

  methods: {
    downloadXml() {
      this.state.downloadXml();
    },
    goLogin() {
      this.router.push("login");
    },

    goLibrary() {
      this.router.replace({ name: "home" });
    },
  },
});

/* eslint-disable no-console */
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; forcing refresh.");
      // If there's a waiting service worker, tell it to skip waiting.
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });

        // Use a regular function to correctly bind `this` to the service worker instance.
        registration.waiting.addEventListener("statechange", function () {
          if (this.state === "activated") {
            // Reload the page to load the new content.
            window.location.reload();
          }
        });
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}

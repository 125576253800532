
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import * as SoundManager from "@/managers/SoundManager";
import { useRoute } from "vue-router";

export default defineComponent({
  mounted() {
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        SoundManager.toggleMute(true);
      } else {
        SoundManager.toggleMute(false);
      }
    });
  },

  data() {
    return {
      appState: getState(),
    };
  },
});

import { defineStore } from "pinia";
import { HomePageEnum } from "./HomePageEnum";
import * as Constants from "@/Constants";
import NewsRecord from "@/model/accounts/NewsRecord";

interface HomeState {
  page: HomePageEnum;
  search: string;
  transcriptionUrl: string;
  transcriptionFileName: string;
  news: NewsRecord[] | null;
}

const defaultState = {
  page: HomePageEnum.Home,
  search: "",
  transcriptionUrl: "",
  transcriptionFileName: "",
  news: null,
};
export const getHomeState = defineStore("HomeState", {
  state: (): HomeState => {
    return Object.assign({}, defaultState);
  },
  actions: {

    navigate(page: HomePageEnum) {
      if (page == HomePageEnum.Transcribe) {
        this.transcriptionUrl = "";
        this.transcriptionFileName = "";
      }
      this.page = page;
    },
  },
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d09872d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept"]
const _hoisted_2 = { class: "input-button-body f12" }
const _hoisted_3 = { class: "flex col align-center g6 p12 mbold f14 light" }
const _hoisted_4 = { class: "flex align-center g6" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "muted",
  style: {"font-weight":"300"}
}
const _hoisted_7 = {
  key: 1,
  class: "f12",
  style: {"font-weight":"300"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["input-button", { 'drag-over': _ctx.dragActive }]),
    onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragEnter && _ctx.dragEnter(...args))),
    onDragleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.dragLeave && _ctx.dragLeave(...args))),
    onDrop: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)))
  }, [
    _createElementVNode("input", {
      ref: "fileInput",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      accept: _ctx.accept,
      type: "file"
    }, null, 40, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
            key: 0,
            color: "#c4c4c4",
            style: {"width":"30px","height":"30px","margin-right":"6px","margin-bottom":"3px"}
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                width: "30",
                height: "30",
                src: '/ico/' + _ctx.icon + '.svg'
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (!_ctx.fileName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.accept), 1))
          : _createCommentVNode("", true),
        (_ctx.fileName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.fileName), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 34))
}
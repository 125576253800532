import IvoryStave from "./IvoryStave";
import IvoryTempoChange from "./IvoryTempoChange";
import IvoryTimeSignature from "./IvoryTimeSignature";

export default class IvorySheet {

  public firstStave: IvoryStave;

  public secondStave: IvoryStave;

  public timeSignatures: IvoryTimeSignature[] = [];

  public tempoChanges: IvoryTempoChange[] = [];

  public title: string = "";

  public key: string = "";

  constructor(title: string, key: string, tempoChanges: IvoryTempoChange[], timeSignatures: IvoryTimeSignature[], firstStave: IvoryStave,
    secondStave: IvoryStave) {
    this.title = title;
    this.key = key;
    this.timeSignatures = timeSignatures;
    this.tempoChanges = tempoChanges;
    this.firstStave = firstStave;
    this.secondStave = secondStave;
  }


  public static fromJson(x: any) {


    return new IvorySheet(x.title, x.key,
      x.tempoChanges.map(x => IvoryTempoChange.fromJson(x)),
      x.timeSignatures.map(x => IvoryTimeSignature.fromJson(x)),
      IvoryStave.fromJson(x.firstStave),
      IvoryStave.fromJson(x.secondStave));
  }

  getMedianTempo(): number {

    const tempos = this.tempoChanges.map(tc => tc.tempo).sort((a, b) => a - b);

    const mid = Math.floor(tempos.length / 2);

    let result = 0;

    // If even, return average of two middle elements
    if (tempos.length % 2 === 0) {
      result = (tempos[mid - 1] + tempos[mid]) / 2;
    } else {
      result = tempos[mid];
    }

    var res = Math.round(result);
    console.log(res);
    return res;
  }
}


import { defineComponent } from "vue";
import { HalfCircleSpinner } from "epic-spinners";
import * as DOMUtils from "@/utils/DOMUtils";
import ProcessingTask from "@/model/ProcessingTask";
import { getState } from "@/state/IvoryState";
import { OrbitSpinner } from 'epic-spinners'
import { FingerprintSpinner } from 'epic-spinners'
import { FlowerSpinner } from 'epic-spinners'
import { SelfBuildingSquareSpinner } from 'epic-spinners'

export default defineComponent({
  props: {
    task: {
      type: ProcessingTask,
      required: true,
    },
  },

  components: { HalfCircleSpinner, OrbitSpinner, FingerprintSpinner, FlowerSpinner, SelfBuildingSquareSpinner },

  data() {
    return {
      account: getState().account,
      DOMUtils: DOMUtils,
    };
  },
  methods: {},
});

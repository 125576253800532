
import { defineComponent, nextTick } from "vue";
import { getState } from "@/state/IvoryState";
import router from "@/router";
import Key from "@/components/player/keys/Key.vue";
import * as ChordManager from "@/managers/ChordManager";
import mixpanel from "mixpanel-browser";
import * as SoundManager from "@/managers/SoundManager";
import * as MidiDeviceManager from "@/managers/MidiDeviceManager";

export default defineComponent({
  mounted() {
    mixpanel.track("Chords page", {});
    MidiDeviceManager.addHook(this.onNoteHook);
    this.updateKeyboardSize();
  },

  data() {
    var state = getState();
    return {
      keysCount: 48,
      startKey: 27,
      state: state,
      chordName: null as string | null,
      playing: false,
      // Array of chords for the circle of fifths
      chords: ["C", "G", "D", "A", "E", "B", "F♯", "C♯", "A♭", "E♭", "B♭", "F"]
    };
  },

  methods: {
    onNoteHook(n: number, pressed: boolean) {
      if (this.hasInput(n)) {
        this.removeInput(n);
      } else {
        this.addInput(n, true, 50, false);
      }
      this.computeChord();
    },
    updateKeyboardSize() {
      var refs = <any>this.$refs;
      let width = refs.pageContainer.offsetWidth;
      this.state.whiteKeyWidth = width / 29 - this.state.keyMargin;
      this.state.blackKeyWidth = this.state.whiteKeyWidth / 2;
      this.state.whiteKeyHeight = window.innerHeight * 0.25;
      this.state.blackKeyHeight = window.innerHeight * 0.155;
    },
    getPressedKeyIds() {
      var results: number[] = [];
      var refs = <any>this.$refs;
      if (refs == undefined) {
        return results;
      }
      for (let i = 1; i <= 88; i++) {
        var key = refs[i];
        if (key != undefined && key[0].hasInput()) {
          results.push(i);
        }
      }
      return results;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async playNotes() {
      this.playing = true;
      let keys = this.getPressedKeyIds();
      for (let key of keys) {
        SoundManager.play(key - 1, 70);
        this.removeInput(key - 1);
        await this.sleep(100);
      }
      for (let key of keys) {
        this.addInput(key - 1, false, 0, false);
      }
      await this.sleep(1000);
      for (let key of keys) {
        SoundManager.play(key - 1, 70);
      }
      await this.sleep(1000);
      this.playing = false;
    },
    onKeyClick(n: number) {
      if (this.hasInput(n)) {
        this.removeInput(n);
      } else {
        this.addInput(n, true, 50, false);
      }
      this.computeChord();
    },
    computeChord() {
      let pressedKeys = this.getPressedKeyIds();
      this.chordName = ChordManager.getChordNameFromIds(pressedKeys, false);
      if (this.chordName == null) {
        this.chordName = ChordManager.getChordNameFromIds(pressedKeys, true);
      }
    },
    removeInput(noteNumber: number) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];
      if (keyRef != undefined) keyRef[0].removeInput();
    },
    hasInput(noteNumber: number) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];
      if (keyRef != undefined) {
        return keyRef[0].hasInput();
      }
      return false;
    },
    onChordNameChange(value: string) {
      this.removeInputs();
      value = value.trim();
      if (value == "") {
        return;
      } else {
        let notes = ChordManager.chordNameToNotes(value);
        if (notes == null) {
          return;
        }
        for (let note of notes) {
          this.addInput(note + this.startKey, true, 50, false);
        }
      }
    },
    addInput(noteNumber: number, play: boolean = true, velocity: number, leftHand: boolean) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];
      if (keyRef != undefined) keyRef[0].addInput(play, velocity, leftHand);
    },
    removeInputs() {
      var refs = <any>this.$refs;
      for (let i = this.startKey; i <= this.startKey + this.keysCount; i++) {
        var key = refs[i][0];
        key.removeAllInputs();
      }
    },
    // Helper method to position each chord label in a circle
    getChordStyle(index: number) {
      const totalChords = this.chords.length;
      // Each chord gets an equal slice of the 360° circle.
      const angle = index * (360 / totalChords);
      // Use a radius slightly less than half the circle's width (here, 180px out of 200px radius)
      const radius = 180;
      // Adjust the angle so 0° starts at the top (subtract 90°)
      const rad = (angle - 90) * (Math.PI / 180);
      const x = radius * Math.cos(rad);
      const y = radius * Math.sin(rad);
      return {
        position: "absolute",
        left: `calc(50% + ${x}px)`,
        top: `calc(50% + ${y}px)`,
        transform: "translate(-50%, -50%)",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold"
      };
    }
  },
  components: { Key },
});

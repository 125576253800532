import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7982e2f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-container" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = {
  key: 0,
  class: "logo light",
  style: {"gap":"8px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      width: 70 * _ctx.sizeMul,
      height: 70 * _ctx.sizeMul,
      style: {"margin-bottom":"12px","filter":"brightness(100%)"},
      src: "/ico/grand-piano.svg"
    }, null, 8, _hoisted_2),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            style: _normalizeStyle('font-size: ' + _ctx.sizeMul * 44 + 'px;'),
            class: "bold"
          }, " Ivory ", 4)
        ]))
      : _createCommentVNode("", true)
  ]))
}
import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvoryStave from "@/model/sheets/IvoryStave";
import { Beam, Note, Stave, StaveNote, StaveTie, TextBracket, Tuplet, Voice } from "vexflow";

export default class Staff {
  bindEvents() {
    /* for (let staveNote of this.voice.getTickables()) {
      staveNote.getSVGElement()!.onmousedown = () => {
        staveNote.setStyle({ fillStyle: "orange", strokeStyle: "orange" });
        staveNote.draw();
      };
    } */
  }
  ivoryMesure: IvoryMeasure;

  staveId: number;
  stave: Stave;

  beams: Beam[] = [];
  ties: StaveTie[] = [];
  voice: Voice;

  initialX: number = 0;
  initialY: number = 0;

  tuplets: Tuplet[] = [];

  constructor(
    staveId: number,
    ivoryMesure: IvoryMeasure,
    stave: Stave,
    beams: Beam[],
    ties: StaveTie[],
    voice: Voice,
    initialX: number,
    initialY: number
  ) {
    this.staveId = staveId;
    this.ivoryMesure = ivoryMesure;
    this.stave = stave;
    this.beams = beams;
    this.ties = ties;
    this.voice = voice;
    this.initialX = initialX;
    this.initialY = initialY;
  }





  public draw(context: any) {
    this.stave.draw();
    this.voice.draw();




    for (let beam of this.beams) {
      beam.draw();
    }

    for (let tie of this.ties) {
      tie.draw();
    }



    if (this.staveId === 0) {
      context!.setFont("Arial", 8, "normal"); // Change font, size, and style

      const measureX = this.stave.getX(); // Get x position for the first note
      const measureY = this.stave.getY() + 30; // Position above the stave
      context!.fillText(this.ivoryMesure.number.toString(), measureX, measureY);
    }



    for (let tuplet of this.tuplets) {
      tuplet.draw();
    }


  }
}
